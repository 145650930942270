
import { ListboxOption }   from '@headlessui/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CDropdownItem',
  components: {
    ListboxOption,
  },
  props: {
    item: {
      type: [ HTMLElement, String, Number ],
      required: true,
    },
  },
  emits: ['select'],
  setup(props, context) {
    const emitSelect = (item: HTMLElement | string | number): void => {
      context.emit('select', item);
    };
    return {
      emitSelect,
    };
  },
});

import {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
} from 'vue-router';
import { Store } from 'vuex';
import { RootState, UserRole } from '@/store/store.types';
import { name as vLogin } from '@/views/login/login.route';
import { name as vResetPassword } from '@/views/resetPassword/reset-password.route';
import { name as vSetPassword } from '@/views/setPassword/set-password.route';
import { name as vDashboard } from '@/views/dashboard/dashboard.route';
import { getUserRole } from '@/helpers/variables';


export const useRouterGuards = (
  router: Router,
  store: Store<RootState>
): void => {
  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (to.name !== vLogin && to.name !== vResetPassword && to.name !== vSetPassword) {
        await store.dispatch('user/getUserData');
      }
      if (to.path.includes('administration')) {
        if (getUserRole() !== UserRole.ADMIN) {
          await router.push({ name: vDashboard });
        }
      }

      store.commit('issues/clearIssuesState')
      next();
    }
  );
};



import { Hotel }  from '@/store/modules/user/user.store';
import { SortBy } from '@/types';

export interface RootState {
  [key: string]: any;
}

export interface PaginationState {
  [key: string]: Pagination;
}

export interface Pagination {
  currentPage: number;
  pageSize: number;
  totalElements: number;
}

export enum UserRole {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
}

export interface ApplicationRootState {
  pageWidth: number;
  pageHeight: number;
  pagination: PaginationState;
  issuesParams: Partial<IssuesParams>;
  overdueIssue: boolean;
}

export interface AddBreadcrumbTranslationPayload {
  key: string;
  translation: string;
  module: string;
}

export interface UserDetailsResponse {
  name: string;
  lastName: string;
  hotel: Hotel;
  role: UserRole;
  isController: boolean;
  uuid: string;
  language: string
}

export interface IssuesParams {
  type: IssueMainType;
  filter: IssueFilterType;
  time: boolean;
  searchQuery: string;
  field: string;
  direction: SortBy;
  timeZone: string;
}

export enum IssueMainType {
  CARE = 'CARE',
  MAINTENANCE = 'MAINTENANCE',
  OBSERVED = 'OBSERVED'
}

export enum IssueFilterType {
  ALL = 'ALL',
  GROUP = 'GROUP',
  ASSIGNED = 'ASSIGNED',
}

export interface AddBreadcrumbTranslationPayload {
  key: string;
  translation: string;
  module: string;
}

export interface UserDetailsResponse {
  name: string;
  lastName: string;
  hotel: Hotel;
  role: UserRole;
  isController: boolean
}

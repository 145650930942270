import {
  AllFiltersConfiguration,
  ColouredPriority,
  ColouredStatus,
  FilterConfigurationFilters, FilterConfigurationOptions,
  FiltersConfiguration,
  Issue,
  IssueNote,
  ListIssue, SelectedFilters
} from '@/views/issues/issues.types';
import { TableColumn } from '@/components/common/table/table.types';
import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { actions } from '@/store/modules/issues/issues.actions';
import { state } from '@/store/modules/issues/issues.state';
import { mutations } from '@/store/modules/issues/issues.mutations';
import { getters } from '@/store/modules/issues/issues.getters';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import {
  Solution
} from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';
import {
  Expectation
} from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { SearchbarSelectItem } from '@/types';

export interface State {
    issues: ListIssue[],
    filtersConfigUuid: string
    selectedIssue: null | Issue,
    followedIssues: Issue[]
    filtersConfiguration: FiltersConfiguration,
    selectedIssueFilters: SelectedFilters
    options: FilterConfigurationOptions
    users: User[],
    tableColumns: TableColumn[],
    statusList: ColouredStatus[];
    priorityList: ColouredPriority[];
    selectedIssueNotes: IssueNote[];
    loading: boolean;
    expectationType: Expectation[],
    solutionType: Solution[],
    allFiltersConfigurations: AllFiltersConfiguration[]
}

const store: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default store


import { defineComponent, onMounted, reactive, ref, Ref, toRefs, watch } from 'vue';
import { useGlobalTranslations }                                         from '@/hooks/use-translations';
import { Scale }                                                         from '@/types';
import { NavigationHighlightStyle }                                      from '@/views/administration/administration.types';
import { useGlobals }                                                    from '@/hooks/use-globals';

export default defineComponent({
  name: 'CAdministrationNavigation',
  setup() {
    const { router } = useGlobals();
    const items: string[] = [
      'users',
      'groups',
      'locationsGroups',
      'locations',
      'issuesTypes',
      'expectationsTypes',
      'solutionsTypes',
    ];

    const liElements: Ref<HTMLElement[]> = ref([]);
    const isMounted: Ref<boolean> = ref(false);
    const divStyles: NavigationHighlightStyle = reactive({
      height: 0,
      width: 0,
      positionY: 0,
      scaleX: 0,
    });
    const currentElement: Ref<string> = ref(items[ 0 ]);

    const navigateTo = (e: { target: HTMLElement }, path: string): void => {
      setStyle(e.target);
      currentElement.value = path;
      router.push({ name: path });
    };

    const getListItemRefs = (el: HTMLElement, index: number): void => {
      liElements.value[ index ] = el;
    };

    const setScaleValue = (el: HTMLElement): void => {
      if (el.innerText.length < 8) {
        divStyles.scaleX = Scale.SMALL;
      } else if (el.innerText.length > 12) {
        divStyles.scaleX = Scale.LARGE;
      } else {
        divStyles.scaleX = Scale.MEDIUM;
      }
    };

    const setStyle = (el: HTMLElement): void => {
      if (!divStyles.height && !divStyles.width) {
        divStyles.height = el.offsetHeight;
        divStyles.width = el.offsetWidth;
      }
      divStyles.positionY = el.offsetTop;
      setScaleValue(el);
    };

    onMounted(() => {
      if (liElements.value.length > 0) {
        const initialRoute: string = router.currentRoute.value.name as string;
        const initialElement: HTMLElement =
            liElements.value[ items.indexOf(initialRoute) ];
        currentElement.value = initialRoute;
        setStyle(initialElement);
        isMounted.value = true;
      }
    });

    watch(() => router.currentRoute.value.path, () => {
      if (router.currentRoute.value.path.includes('users')) {
        const element: HTMLElement =
            liElements.value[ items.indexOf('users') ];
        currentElement.value = 'users';
        setStyle(element);
      }
    });

    return {
      items,
      liElements,
      isMounted,
      currentElement,
      ...toRefs(divStyles),
      navigateTo,
      getListItemRefs,
      useGlobalTranslations,
    };
  },
})
;

import { FilterTypes } from '@/types';

export interface ActionData {
  icon: string;
  name: string;
}

export interface Data {
  content: string | boolean | Tag[] | Tag | ActionData[];
  event?: Record<string, unknown>;
}

export enum columnTypes {
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  COLOR = 'COLOR',
  TAGS = 'TAGS',
  EMAIL = 'EMAIL',
  ACTIONS = 'ACTIONS',
}

export interface TableColumn {
  name: string;
  type: columnTypes;
  filter?: FilterTypes;
  filterOptions?: Tag[];
  selectedOptions?: Tag[];
}

export interface TableRow {
  uuid: string;

  [s: string]: string | boolean | Tag[] | ActionData[];
}

export interface DataError {
  type: string;
  occurrence: string | boolean | Tag[] | ActionData[];
}

export interface DataValidation {
  error: boolean;
  info?: DataError;
}

export interface Tag {
  color: string;
  name: string;
  uuid?: string;
}

export enum EventType {
  RECORD = 'record',
  SORT = 'sort',
  DATA = 'data',
}

export interface FilterSortBy {
    name: string;
    type: filterType;
    values: SortBy[];
}

export enum ConfirmationStatement {
    DELETE_ITEM_CONFIRMATION = 'DELETE_CONFIRMATION',
    SAVE_ITEM_CONFIRMATION = 'SAVE_ITEM_CONFIRMATION'

}

export interface RadioGroup {
    color?: string,
    value: string,
    name: string
}

export interface GetFiltersConfiguration {
    filterType: MainType
    configurationUuid?: string
}

export interface FilterGroupSelect {
    name: string;
    type: filterType;
    values: FilterGroupValues[];
    selectedValues?: string[] | string;
}

export interface FilterGroupValues {
    name: string;
    color: string;
    uuid?: string;
}

export interface FilterGroupSelect {
    name: string;
    type: filterType;
    values: FilterGroupValues[];
}

export type FilterGroup = FilterSortBy | FilterGroupSelect;

export interface TableSortEvent {
    column: string;
    direction: string;
    el?: string;
}

export enum SidePanelState {
    DELETE_ENTITY = 'DELETE_ENTITY',
    EDIT_ENTITY = 'EDIT_ENTITY'
}

export interface AdminEndpoint {
    endpoint: string;
}

export enum switchTypes {
    DEFAULT = 'default',
    DOUBLE = 'double',
    SINGLE = 'single',
}

export enum buttonTypes {
    GREEN = 'green',
    WHITE = 'white',
    LIGHT = 'light',
    GRAY = 'gray',
    GRAYDARK = 'gray-dark',
    RED = 'red',
    DASHED = 'dashed',
    ONLYTEXT= 'only-text'
}

export enum iconTypes {
    MAINTENANCE = 'IconMaintenance',
    INSURANCE = 'IconInsurance',
    VISIBILITY = 'IconVisibility',
    PERSON = 'IconPerson',
    PEOPLE = 'iconPeople',
    SQUARES = 'iconSquares',
    PLUS = 'iconPlus',
    CONTROL = 'IconControl',
    TRASH_CAN = 'IconTrashCan',
    CLOSE = 'IconClose',
    DISCARD = 'IconDiscard',
    SAVE = 'IconSave'
}

export enum IconButtonClass {
    RED = 'RED',
    DISABLED = 'DISABLED',
    GREEN = 'GREEN'
}

export enum MainType {
    MAINTENANCE = 'MAINTENANCE',
    CARE = 'CARE',
    WATCHED = 'OBSERVED',
}

export enum columnHeaderIcon {
    SORT = 'SORT',
    DROPDOWN = 'DROPDOWN',
}

export enum FilterTypes {
    MULTI_SELECT = 'MULTI_SELECT',
    SORT = 'SORT',
    SINGLE_SELECT = 'SINGLE_SELECT',
}

export enum tagTypes {
    ADMIN = 'ROLE_ADMIN',
    USER = 'ROLE_USER',
    OTHER = 'ROLE_OTHER',
}

export enum Sort {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum Scale {
    LARGE = 1,
    MEDIUM = 0.8,
    SMALL = 0.6,
}

export interface AdministrationParams {
    searchQuery: string;
    page: number;
    size: number;
    groups: string[];
    'sortBy[0].direction': string;
    'sortBy[0].field': string;
    sortBy: { direction: string, field: string };
    mainType?: string,
}

export interface AdministrationFilters {
    [s: string]: string[];
}

export interface sortPayload {
    field: string;
    direction: SortBy;
}

export type SortBy = 'ASC' | 'DESC';

export type filterType = 'MULTI_SELECT' | 'SORT' | 'SINGLE_SELECT';

export interface FormState {
    [s: string]: string | tagTypes[] | configurationTagTypes[] | boolean | number;
}

export enum configurationTagTypes {
    USER = 'ROLE_USER',
    ADMIN = 'ROLE_ADMIN',
}

export interface DataWithPagination<T> {
    content: T;
    pageSize: number;
    currentPage: number;
    totalElements: number;
}

export interface SearchbarSelectItem {
    uuid: string;
    label: string;
}

export interface SelectItem {
    value: string
    name: string
}

export interface AddNoteAndChangeStatus {
    uuid: string;
    note: string;
}


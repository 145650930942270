
import { defineComponent }      from 'vue';
import { UserRole }             from '@/store/store.types';
import { useApplicationHeader } from '@/components/application-header/use-application-header';
import cLogo                    from '@/components/common/logo/logo.component.vue';
import cOptionsDropdown         from '@/components/common/options-dropdown/options-dropdown.component.vue';
import cHotelLabel              from '@/components/common/hotel-label/hotel-label.component.vue';
import CNavigation              from '@/components/application-header/components/navigation.component.vue';
import { cogwheelIcon }         from '@/helpers/variables';


export default defineComponent({
  name: 'CApplicationHeader',
  components: {
    CNavigation,
    cHotelLabel,
    cOptionsDropdown,
    cLogo,
  },
  setup() {
    const { elementList: navigationList, currentUserHotel, currentUserFullName } = useApplicationHeader();

    return {
      navigationList,
      currentUserHotel,
      currentUserFullName,
      UserRole,
      cogwheelIcon,
    };
  },
});

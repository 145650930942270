import { useGlobals } from '@/hooks/use-globals';
import { ChangeIssueStatusPayload } from '@/views/controller/controller.types';
import {
  AddNotePayload, EditIssuePayload,
  IssueStatus, SelectedFilters
} from '@/views/issues/issues.types';
import { GetFiltersConfiguration, MainType } from '@/types';

export enum UseIssuesContext {
    CONTROLLER = 'CONTROLLER',
    ISSUES = 'ISSUES',
}

interface UseIssues {
    getAllIssues: (viewContext?: UseIssuesContext) => void;
    getSelectedIssue: (uuid: string) => Promise<void>;
    getFile: (imageId: string) => Promise<void>;
    updateFiltersAndGetAllIssues: (
        payload: any
    ) => Promise<void>;
    addNoteAndChangeStatus: (payload: AddNotePayload) => Promise<void>;
    addNoteAndGetAllNotes: (payload: AddNotePayload) => Promise<void>;
    changeStatusAndGetAllIssues: (
        payload: ChangeIssueStatusPayload
    ) => Promise<void>;
    editIssueAndGetAllIssues: (payload: EditIssuePayload) => Promise<void>;
    changeFollowIssueStatement: (
        followIssueStatement: boolean,
        uuid: string
    ) => Promise<void>;
    deleteIssueAndGetAllIssues: (issueUuid: string) => Promise<void>;
    getFiltersConfiguration: (payload: GetFiltersConfiguration) => Promise<void>;
    updateFiltersConfiguration: (
        payload: SelectedFilters
    ) => Promise<void>;
    getPDF: (uuid: string) => Promise<void>;
    createFiltersConfiguration: (payload?: string) => Promise<void>;
    getAllFiltersConfigurations: (filterType: MainType) => Promise<void>
    deleteFiltersConfiguration: (configUuid: string) => Promise<void>
}

export const useIssues = (
  initialIssuesContext: UseIssuesContext
): UseIssues => {
  const { store } = useGlobals();

  const addNoteAndChangeStatus = async ({
    issueUuid,
    description,
    files,
  }: AddNotePayload): Promise<void> => {
    await addNewNote({ description, files, issueUuid });
    await changeStatusAndGetAllIssues({
      uuid: issueUuid,
      issueStatus: IssueStatus.IN_PROGRESS,
    });
  };

  const addNoteAndGetAllNotes = async ({
    issueUuid,
    description,
    files,
  }: AddNotePayload): Promise<void> => {
    await addNewNote({ issueUuid, description, files });
    await getAllNotes(issueUuid);
  };

  const changeStatusAndGetAllIssues = async (
    payload: ChangeIssueStatusPayload
  ): Promise<void> => {
    await changeIssueStatus(payload);
    await getSelectedIssue(payload.uuid);
    getAllIssues();
  };

  const updateFiltersAndGetAllIssues = async (
    payload: SelectedFilters
  ) => {
    await updateFiltersConfiguration(payload);
    await getFiltersConfiguration({ filterType: payload.filterType });
    getAllIssues();
  };

  const editIssueAndGetAllIssues = async ({
    issueUuid,
    modifyIssueData,
  }: EditIssuePayload): Promise<void> => {
    await store.dispatch('issues/editIssue', modifyIssueData);
    getAllIssues();
    getSelectedIssue(issueUuid);
  };

  const getAllIssues = (viewContext?: UseIssuesContext): void => {
    const context = viewContext || initialIssuesContext;

    context === UseIssuesContext.CONTROLLER
      ? store.dispatch('issues/getControlledIssues')
      : store.dispatch('issues/getIssues');
  };

  const getSelectedIssue = async (uuid: string): Promise<void> => {
    await store.dispatch('issues/getSelectedIssue', uuid);
    await getAllNotes(uuid);
  };

  const changeFollowIssueStatement = async (
    followIssueStatement: boolean,
    uuid: string
  ) => {
    followIssueStatement
      ? await store.dispatch('issues/followIssue', uuid)
      : await store.dispatch('issues/unFollowIssue', uuid);
  };

  const deleteIssueAndGetAllIssues = async (issueUuid: string) => {
    await store.dispatch('issues/deleteIssue', issueUuid);
    getAllIssues();
  };

  const changeIssueStatus = async (payload: ChangeIssueStatusPayload): Promise<void> => await store.dispatch('issues/changeIssueStatus', payload);

  const addNewNote = async (payload: AddNotePayload): Promise<void> => await store.dispatch('issues/addNoteToIssue', payload);

  const getAllNotes = async (uuid: string): Promise<void> => await store.dispatch('issues/getIssuesNotes', uuid);

  const getFile = async (fileUuid: string): Promise<void> => await store.dispatch('issues/getFile', fileUuid);

  const updateFiltersConfiguration = async (payload: SelectedFilters): Promise<void> => {
    await createBaseConfiguration(payload.filterType);
    await store.dispatch('issues/updateFilters', payload);
  }

  const getAllFiltersConfigurations = async (type: MainType): Promise<void> => {
    await createBaseConfiguration(type)
    store.dispatch('issues/getAllFiltersConfigurations', type);
  }

  const getFiltersConfiguration = async (payload: GetFiltersConfiguration): Promise<void> => {
    await createBaseConfiguration(payload.filterType)
    store.dispatch('issues/getFiltersConfiguration', payload);
  }

  const createFiltersConfiguration = async (payload?: string): Promise<void> => await store.dispatch('issues/createFilterConfiguration', payload)

  const deleteFiltersConfiguration = async (configUuid: string): Promise<void> => await store.dispatch('issues/deleteFilterConfiguration', configUuid)

  const getPDF = async (issueUuid: string): Promise<void> => store.dispatch('issues/getPDF', issueUuid);

  const createBaseConfiguration = async (filterType: MainType): Promise<void> => store.dispatch('issues/createBaseConfiguration', filterType)

  return {
    deleteFiltersConfiguration,
    createFiltersConfiguration,
    getAllFiltersConfigurations,
    updateFiltersConfiguration,
    getSelectedIssue,
    getAllIssues,
    getFile,
    addNoteAndGetAllNotes,
    editIssueAndGetAllIssues,
    updateFiltersAndGetAllIssues,
    addNoteAndChangeStatus,
    deleteIssueAndGetAllIssues,
    changeFollowIssueStatement,
    changeStatusAndGetAllIssues,
    getFiltersConfiguration,
    getPDF,
  };
};

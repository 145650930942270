import { MutationTree } from 'vuex';
import { State } from '@/store/modules/issues/issues.store';
import {
  AllFiltersConfiguration, FilterConfigurationOptions,
  FiltersConfiguration,
  Issue,
  IssueNote,
  ListIssue,
  SelectedFilters
} from '@/views/issues/issues.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import { MainType } from '@/types';
import {
  Expectation
} from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import {
  Solution
} from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';

export const mutations: MutationTree<State> = {
  changeListIssueStatus(state, payload: any): void {
    state.issues = state.issues.map((issue) => {
      if (issue.uuid === payload.issueUuid) {
        return {
          ...issue,
          status: payload.status,
        };
      }
      return issue;
    });
  },
  clearIssuesState(state) {
    state.issues = [];
    state.selectedIssue = null;
  },
  setIssues(state, payload: ListIssue[]) {
    state.issues = payload
  },

  setSelectedFilters(state, payload: SelectedFilters) {
    state.selectedIssueFilters = payload
  },

  setIssueOptions(state, payload: FilterConfigurationOptions) {
    state.options = { ...state.options, ...payload }
  },

  setSelectedIssue(state, payload: Issue) {
    state.selectedIssue = payload
  },

  setControlledIssueNotes(state, payload: IssueNote[]) {
    state.selectedIssueNotes = payload;
  },

  setAllFiltersConfigurations(state, payload: AllFiltersConfiguration[]) {
    state.allFiltersConfigurations = payload
  },

  setLoading(state, isLoading: boolean) {
    state.loading = isLoading;
  },
  setFiltersConfiguration(state, payload: FiltersConfiguration): void {
    state.filtersConfiguration = {
      ...payload,
      filters: {
        ...payload.filters,
        min: payload.filters.min || '',
        max: payload.filters.max || '',
        createdMin: payload.filters.createdMin || '',
        createdMax: payload.filters.createdMax || '',
        filterType: payload.filters.filterType || MainType.MAINTENANCE
      },
    };
  },
  setAllUsers(state, payload: User[]) {
    state.users = payload
  },

  changeSelectedIssueObserved(state, payload: boolean): void {
    if (state.selectedIssue) state.selectedIssue.isObserved = payload;
  },
  setExpectationsTypes(state, expectationType: Expectation[]): void {
    state.expectationType = expectationType;
  },
  setSolutionsTypes(state, solutionType: Solution[]): void {
    state.solutionType = solutionType;
  },
  setFiltersConfigUuid(state, id: string): void {
    state.filtersConfigUuid = id;
  }
}

import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/modules/issues/issues.store';
import { IssueMainType, RootState } from '@/store/store.types';
import { getControllerIssues } from '@/views/controller/controller.service';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';
import {
  AddNotePayload,
  FilterConfigurationFilters,
  Issue,
  IssueEditPayload,
  IssueNote,
  IssuesEndpoints,
  ModifyIssue,
  SelectedFilters,
} from '@/views/issues/issues.types';
import {
  createFilterConfiguration,
  deleteFilterConfiguration,
  deleteIssue,
  followIssue,
  getAllFiltersConfigurations,
  getFiltersConfiguration,
  getIssues,
  getPDF,
  getEXCEL,
  getSingleIssue,
  getSingleNote,
  postBaseFilterConfiguration,
  postNewNote,
  setFiltersConfiguration,
  setIssue,
  unFollowIssue,
  updateIssueStatus,
} from '@/views/issues/issues.service';
import { ChangeIssueStatusPayload } from '@/views/controller/controller.types';
import { modifyIssueToPutPayload } from '@/views/issues/helpers/helpers';
import { cloneDeep } from 'lodash-es';
import {
  getSingleEntity,
  getTableData,
} from '@/views/administration/administration.service';
import {
  downloadPDFFile,
  mappedFilesResource,
} from '@/store/modules/issues/issues.helpers';
import {
  ISSUES_PAGINATION,
  USERS_PAGINATION,
} from '@/views/issues/helpers/variables';
import {
  AdministrationParams,
  GetFiltersConfiguration,
  MainType,
} from '@/types';
import {
  AdministrationEndpoints,
  AdministrationPagination,
} from '@/views/administration/administration.types';

const sanitizeFilters = ({
  assignedUsers,
  createdByUsers,
  issueTypes,
  locations,
  otherGroups,
  issueStatuses,
  min,
  max,
  createdMax,
  createdMin,
  ...filters
}: FilterConfigurationFilters): SelectedFilters => {
  return {
    ...filters,
    dateRange: {
      min,
      max,
    },
    createdDateRange: {
      min: createdMin,
      max: createdMax,
    },
    statuses: issueStatuses,
    assignedUsers: assignedUsers.map(({ uuid }) => uuid),
    createdByUsers: createdByUsers?.map(({ uuid }) => uuid) ?? [],
    locations: locations.map(({ uuid }) => uuid),
    otherGroups: otherGroups.map(({ uuid }) => uuid),
    issueTypes: issueTypes.map(({ uuid }) => uuid),
  };
};

export const actions: ActionTree<State, RootState> = {
  async getControlledIssues({ commit }): Promise<void> {
    commit('setLoading', true);
    try {
      const { data } = await getControllerIssues();
      commit('setIssues', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },

  async getIssues({ commit, rootState }): Promise<void> {
    commit('setLoading', true);

    const { pageSize, currentPage } = rootState.pagination[ISSUES_PAGINATION];

    try {
      const allParams = cloneDeep(rootState.issuesParams);
      !allParams.searchQuery && delete allParams.searchQuery;

      const { data } = await getIssues({
        ...allParams,
        size: pageSize,
        page: currentPage,
      });
      commit('setIssues', data.content);

      commit(
        'updatePaginationPageSize',
        { value: data.size, name: ISSUES_PAGINATION },
        { root: true }
      );
      commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: ISSUES_PAGINATION,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },

  async editIssue(context, payload: IssueEditPayload): Promise<void> {
    try {
      await setIssue(payload, context?.state?.selectedIssue?.uuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vIssues.notifications.updated')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async deleteIssue(
    context: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      await deleteIssue(IssuesEndpoints.ISSUES, uuid);
      //todo router push to lists view
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vIssues.notifications.deleted')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async changeUser({ state, dispatch }, userUuid: string): Promise<void> {
    const selectedIssue: Issue | null = state.selectedIssue;
    if (selectedIssue === null) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.somethingWentWrong`)
      );
    } else {
      try {
        const modifiedIssue: IssueEditPayload = modifyIssueToPutPayload(
          cloneDeep(selectedIssue),
          ModifyIssue.USER,
          userUuid
        );
        await dispatch('editIssue', modifiedIssue);
        await dispatch('getIssues');
        dispatch('getSelectedIssue', selectedIssue.uuid);
      } catch (e) {
        throw e.data;
      }
    }
  },
  async followIssue(
    context: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      await followIssue(uuid);
      context.commit('changeSelectedIssueObserved', true);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async unFollowIssue(
    context: ActionContext<State, RootState>,
    uuid: string
  ): Promise<void> {
    try {
      await unFollowIssue(uuid);
      context.commit('changeSelectedIssueObserved', false);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getIssuesNotes({ commit }, uuid: string): Promise<void> {
    try {
      const { data }: { data: IssueNote[] } = await getSingleNote(uuid);
      commit('setControlledIssueNotes', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getSelectedIssue({ commit }, uuid): Promise<void> {
    try {
      const { data } = await getSingleIssue(uuid);
      commit('setSelectedIssue', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async changeIssueStatus(
    context,
    { uuid, issueStatus }: ChangeIssueStatusPayload
  ) {
    try {
      await updateIssueStatus(uuid, issueStatus);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async addNoteToIssue(
    { commit },
    { files, description, issueUuid }: AddNotePayload
  ) {
    try {
      commit('setLoading', true);
      const fileUuids =
        (files.length && (await mappedFilesResource(files))) ||
        ([] as string[]);
      await postNewNote({ description: description, fileUuids }, issueUuid);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },

  async getIssueOptions({ commit }, payload: GetFiltersConfiguration) {
    const { data } = await getFiltersConfiguration(payload);
    commit('setIssueOptions', data.options);
  },

  async getFiltersConfiguration(
    { commit },
    payload: GetFiltersConfiguration
  ): Promise<void> {
    commit('setLoading', true);
    try {
      const { data } = await getFiltersConfiguration(payload);
      commit('setSelectedFilters', sanitizeFilters(data.filters));

      payload.configurationUuid &&
        commit('setFiltersConfigUuid', payload.configurationUuid);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async updateFiltersConfiguration(
    { commit, rootState },
    payload: FilterConfigurationFilters
  ): Promise<void> {
    commit('setLoading', true);
    try {
      await setFiltersConfiguration({
        ...payload,
        filterType: rootState.issuesParams.type,
      });
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateFilters({ rootState }, payload: SelectedFilters) {
    try {
      await setFiltersConfiguration({
        ...payload,
        filterType: rootState.issuesParams.type,
      });
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getFile(context, fileUuid: string): Promise<any> {
    try {
      return await getSingleEntity(IssuesEndpoints.FILES, fileUuid);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getAllUsers({ commit, rootState }, payload = ''): Promise<void> {
    try {
      const { pageSize, currentPage } = rootState.pagination[USERS_PAGINATION];
      const allParams: Partial<AdministrationParams> = {
        page: currentPage,
        size: pageSize,
        searchQuery: payload,
      };

      const { data } = await getTableData(
        AdministrationEndpoints.USERS,
        allParams
      );
      commit('setAllUsers', data.content);
      commit(
        'updatePaginationElements',
        {
          totalElements: data.totalElements,
          name: USERS_PAGINATION,
        },
        { root: true }
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getPDF(context, issueUuid: string): Promise<void> {
    try {
      const { data } = await getPDF(issueUuid);
      const file = new Blob([data], { type: 'application/pdf' });
      downloadPDFFile(file, issueUuid);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async getExpectationsTypes({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(
        AdministrationEndpoints.EXPECTATIONS_TYPES,
        {}
      );
      commit('setExpectationsTypes', data.content);
    } catch (e) {
      throw e.data;
    }
  },
  async getSolutionsTypes({ commit }): Promise<void> {
    try {
      const { data } = await getTableData(
        AdministrationEndpoints.SOLUTIONS_TYPES,
        {}
      );
      commit('setSolutionsTypes', data.content);
    } catch (e) {
      throw e.data;
    }
  },

  async getAllFiltersConfigurations(
    { commit },
    issueMainType: IssueMainType
  ): Promise<void> {
    try {
      const { data } = await getAllFiltersConfigurations(issueMainType);

      commit('setAllFiltersConfigurations', data);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async createFilterConfiguration({ rootState }, payload): Promise<void> {
    try {
      await createFilterConfiguration({
        filterType: rootState.issuesParams.type,
        name: payload,
      });
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vIssues.notifications.saveConfig')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async deleteFilterConfiguration(
    context,
    configurationUuid: string
  ): Promise<void> {
    try {
      await deleteFilterConfiguration(configurationUuid);
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vIssues.notifications.deletedConfig')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async createBaseConfiguration(
    { rootState },
    filterType: MainType
  ): Promise<void> {
    try {
      await postBaseFilterConfiguration(rootState.issuesParams.type);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },

  async generateExcel({ rootState }): Promise<void> {
    const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const { data } = await getEXCEL({
        ...rootState.issuesParams,
        lang: i18n.global.locale.value,
        timeZone: TZ,
      });
      const file = new Blob([data], { type: 'application/xlsx' });
      const date = new Date();
      const offset = date.getTimezoneOffset();
      const rightDate = new Date(date.getTime() - offset * 60 * 1000);
      const filerUrl = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      fileLink.href = filerUrl;
      fileLink.setAttribute(
        'download',
        `Issues_${rightDate.toISOString().substring(0, 10)}.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
};

import { MutationTree }                                                          from 'vuex';
import { ApplicationRootState, IssueFilterType, IssueMainType, PaginationState } from '@/store/store.types';
import { sortPayload }                                                           from '@/types';

export const applicationRootMutations: MutationTree<ApplicationRootState> = {
  setPageWidth(state, width: number): void {
    state.pageWidth = width;
  },
  setPageHeight(state, height: number): void {
    state.pageHeight = height;
  },
  setPagination(state, pagination: string[]): void {
    pagination.forEach((paginationName: string) => {
      state.pagination[paginationName as keyof PaginationState] = {
        currentPage: 1,
        pageSize: 20,
        totalElements: 0,
      };
    });
  },
  updatePaginationElements(
    state,
    payload: { totalElements: number; name: string }
  ): void {
    state.pagination[payload.name].totalElements = payload.totalElements;
  },
  updatePaginationPageSize(
    state,
    payload: { value: number; name: string }
  ): void {
    state.pagination[payload.name].pageSize = payload.value;
  },
  updatePaginationCurrentPage(
    state,
    payload: { page: number; name: string }
  ): void {
    state.pagination[payload.name].currentPage = payload.page;
  },

  setIssueParamsType(state, payload: IssueMainType) {
    state.issuesParams.type = payload;
  },
  setIssueParamsFilter(state, payload: IssueFilterType) {
    state.issuesParams.filter = payload
  },
  setIssueParamsTime(state, payload: boolean) {
    state.issuesParams.time = payload
  },
  setIssueParamsSearchQuery(state, payload: string) {
    state.issuesParams.searchQuery = payload
  },
  setIssueParamsSort(state, payload: sortPayload){
    state.issuesParams.direction = payload.direction;
    state.issuesParams.field = payload.field;
  },
  setOverdueIssue(state, payload: boolean) {
    state.overdueIssue = payload
  }
};

import axios, { AxiosPromise } from 'axios';
import { getUrl }              from '@/service/service.helpers';
import { client }              from '@/service/client';
import { UserNotificationsResponse }     from '@/store/modules/user/user.store';

export const getUserData = (): AxiosPromise =>
  axios.get(getUrl('/users/current'));
export const logoutUser = (): AxiosPromise =>
  axios.get(getUrl('/users/logout'));

export const changeUserName = async (userUuid: string, payload: {name?: string, lastName?: string}): Promise<AxiosPromise> => {
  return await client.put(`users/${userUuid}/update`, payload)
}

export const changeUserNotifications = async (userUuid: string, payload: UserNotificationsResponse): Promise<AxiosPromise> => {
  return await client.put(`users/${userUuid}/notification`, {
    ...payload,
    phoneNumber: payload.smsNotificationsEnabled ? payload.phoneNumber : null
  })
}

export const getUserNotifications = async (userUuid: string): Promise<AxiosPromise> => {
  return await client.get(`users/${userUuid}/notification`)
}


import { IssueType } from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';
import { Location } from '@/views/administration/views/administration-locations/administration-locations.types';
import { ValidationRuleWithoutParams } from '@vuelidate/core';
import { TableColumn } from '@/components/common/table/table.types';
import { MainType } from '@/types';
import { Expectation } from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import { Solution } from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';

export enum IssuesEndpoints {
  ISSUES = 'issues',
  FILES = 'files',
}

export interface State {
  isSidePanelVisible: boolean;
  isEditMode: boolean;
  tableColumns: TableColumn[];
  viewMode: ViewModes;
}

export enum ViewModes {
  TABLE = 'Table',
  LIST = 'List',
}

export interface ColouredStatus {
  name: IssueStatus;
  color: string;
}

export interface Issue {
  uuid: string;
  name: string;
  creationDate: string;
  deadline: string;
  endDate: string;
  lastUpdateDate: string;
  issueStatus: IssueStatus;
  mainType: IssueMainType;
  issueType: IssueType;
  issueCreator: Omit<
    User,
    'email' | 'status' | 'role' | 'permissions' | 'group'
  >;
  location: Omit<Location, 'groups' | 'locationGroups'>;
  hotelUser: Omit<User, 'email' | 'status' | 'role' | 'permissions' | 'group'>;
  controller: Omit<User, 'email' | 'status' | 'role' | 'permissions' | 'group'>;
  group: Omit<Group, 'color'>;
  guestName: string;
  guestDescription: string;
  expectations: string;
  expectationType: Expectation;
  solutionType: Solution;
  solution: string;
  priority: IssuePriority;
  isObserved: boolean;
  relatedIssues: IssueRelatedIssues[];
  issueNumber: string;
}

export interface ListIssue {
  uuid: string;
  status: IssueStatus;
  name: string;
  mainType: string;
  priority: IssuePriority;
  hotelUser: IssueFiltersItem;
  location: IssueFiltersItem;
  daysToDeadline: number;
  deadline: string;
  creationDate: string;
  issueNumber: string;
}

export interface FiltersConfiguration {
  filters: FilterConfigurationFilters;
  options: FilterConfigurationOptions;
}

export interface FilterConfigurationFilters {
  issueFilterConfigurationUuid: string;
  priorities: IssuePriority[];
  issueStatuses: IssueStatus[];
  deadlineOver: boolean;
  issueTypes: IssueFiltersItem[];
  otherGroups: IssueFiltersItem[];
  assignedUsers: IssueFiltersItem[];
  createdByUsers: IssueFiltersItem[];
  locations: IssueFiltersItem[];
  guests: string[];
  min: string | null;
  max: string | null;
  createdMin: string | null;
  createdMax: string | null;
  filterType: MainType;
}

export interface SelectedFilters {
  issueFilterConfigurationUuid: string;
  priorities: IssuePriority[];
  statuses: IssueStatus[];
  deadlineOver: boolean;
  issueTypes: string[];
  otherGroups: string[];
  assignedUsers: string[];
  createdByUsers: string[];
  locations: string[];
  guests: string[];
  dateRange: {
    min: string | null;
    max: string | null;
  };
  createdDateRange: {
    min: string | null;
    max: string | null;
  };
  filterType: MainType;
}

export interface FilterConfigurationPutPayload {
  priorities: IssuePriority[];
  issueStatuses: IssueStatus[];
  deadlineOver: boolean;
  issueTypes: string[];
  otherGroups: string[];
  assignedUsers: string[];
  locations: string[];
  guests: string[];
  min: string | null;
  max: string | null;
  createdMin: string | null;
  createdMax: string | null;
  filterType: MainType;
}

export enum SpecificFilters {
  CREATED_DATE_RANGE = 'createdDateRange',
  DATE_RANGE = 'dateRange',
  OVERDUE = 'deadlineOver',
  STATUSES = 'statuses',
  PRIORITIES = 'priorities',
  GUESTS = 'guests',
  TYPE = 'filterType',
  OTHER_GROUPS = 'otherGroups',
}

export interface DateRange {
  min: string;
  max: string;
}

export interface FilterConfigurationOptions {
  issueTypes: IssueFiltersItem[];
  locations: IssueFiltersItem[];
  users: IssueFiltersItem[];
  guests: string[];
  groups: Group[];
  expectationType: Expectation[];
  solutionType: Solution[];
}

export interface IssueEditPayload {
  name: string;
  deadLine: Date;
  issueStatus: IssueStatus;
  issueMainType: IssueMainType;
  hotelUserUuid: string;
  locationUuid: string;
  guestName: string;
  guestDescription: string;
  priority: IssuePriority;
  expectation: string;
  solution: string;
  expectationTypeUuid: string | null;
  solutionTypeUuid: string | null;
  issueTypeUuid: string;
  controllerUuid: string;
  groupUuid: string;
  relatedIssues: string[];
}

export interface IssueFiltersItem {
  uuid: string;
  name: string;
}

export interface IssueNote {
  uuid: string;
  user: Omit<User, 'email' | 'status' | 'role' | 'permissions' | 'groups'>;
  creationDate: string;
  noteType: NoteType;
  description: string;
  media: string[];
}

export enum NoteType {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum IssueStatus {
  NEW = 'NEW',
  TODO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  ASSIGNED = 'ASSIGNED',
  DONE = 'DONE',
  ENDED = 'ENDED',
}

export enum IssuePriority {
  URGENT = 'URGENT',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum FileType {
  IMAGE = 'IMAGE',
  OTHER = 'OTHER',
}

export interface ColouredPriority {
  name: IssuePriority;
  color: string;
}

export enum IssueMainType {
  CARE = 'CARE',
  MAINTENANCE = 'MAINTENANCE',
}

export interface LeftSideTitle {
  name: string;
  class: string;
}

export enum ModifyIssue {
  USER = 'USER',
  STATUS = 'STATUS',
}

export interface FormValues {
  description: string;
  files: File[];
}

export interface EditIssueFormRules {
  name: { required: ValidationRuleWithoutParams };
  priority: { required: ValidationRuleWithoutParams };
  locationUuid: { required: ValidationRuleWithoutParams };
  groupUuid: { required: ValidationRuleWithoutParams };
  issueTypeUuid: { required: ValidationRuleWithoutParams };
  controllerUuid: { required: ValidationRuleWithoutParams };
}

export interface IssueRelatedIssues {
  uuid: string;
  name: string;
  issueNumber: string;
}

export interface EditIssuePayload {
  issueUuid: string;
  modifyIssueData: IssueEditPayload;
}

export interface AddNotePayload {
  issueUuid: string;
  description: string;
  files: File[];
}

export interface CreateFiltersConfiguration {
  name: string;
}

export interface AllFiltersConfiguration {
  configUuid: string;
  name: string;
}

export interface FilterParams {
  type: string;
  filter: string;
  time: boolean;
  issueStatuses: string[];
}

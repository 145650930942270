import axios, { AxiosInstance } from 'axios';
import { getHotelUuid }                   from '@/helpers/variables';
import * as Qs                from 'qs';
import { RESPONSE_STATUS }      from '@/service/service';
import providers              from '@/providers';
import { name as vLogin } from '@/views/login/login.route'

export const client: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_PROXY}/hotels`,
});

client.interceptors.request.use((config) => {
  config.baseURL = `${config.baseURL}/${getHotelUuid()}`
  config.paramsSerializer = (params) =>
    Qs.stringify(params, { arrayFormat: 'repeat' });
  return config;
});


client.interceptors.response.use(config => config,
  (error) => {
    if(error.response
        && error.response.status === RESPONSE_STATUS.UNAUTHORIZED
        || error.response.status === RESPONSE_STATUS.FORBIDDEN) {

        providers?.router?.push({ name: vLogin })
    }

    return Promise.reject(error.response);
  }
);


import { defineComponent }      from 'vue';
import CirceIconWrapper       from '@/components/icons/wrapper/circle-icon-wrapper.component.vue';
import CLogoutButton          from '@/components/common/logout-button/logout-button.component.vue';
import CLogo                  from '@/components/common/logo/logo.component.vue';
import { name as vDashboard }   from '@/views/dashboard/dashboard.route';
import { useApplicationHeader } from '@/components/application-header/use-application-header';


export default defineComponent({
  name: 'CLeftSideBar',
  components: {
    CLogo,
    CLogoutButton,
    CirceIconWrapper,
  },
  setup() {
    const { activeIcon, navigationElements } = useApplicationHeader()

    return {
      vDashboard,
      activeIcon,
      navigationElements
    };
  },
});

import providers from '@/providers';

export const GLOBAL = '__global__';
export const cogwheelIcon = 'iconCogwheel';
export const moreIcon = 'iconMore';
export const DROPDOWN_NAVIGATION = ['Hotels', 'Operators', 'Logout'];
export const ASC = 'ASC';
export const DESC = 'DESC';
export const Err = 'Error';
export const A_TO_Z = 'A-Z';
export const Z_TO_A = 'Z-A';
export const grayDefault = '#6b7280';
export const primaryColor = '#009629'
export const administration = 'administration';
export const vUsers = 'users';
export const defaultCompressionConfig = { compressionRatio: 0.5, quality: 0.7, acceptableSizingThreshold: 70000 }

export const dashboardColors = ['#B0C4DE', '#E9967A', '#00CED1', '#8A2BE2', '#B22222', '#DAA520', '#624d4b', '#8FBC8F', '#7FFFD4', '#FF1493', '#7B3F00', '#3CB371', '#D2B48C', '#DC143C', '#C0C0C0',
  '#8F00FF', '#48D1CC', '#90EE90', '#BA55D3', '#0000CD', '#4169E1', '#DB7093', '#9966CC', '#673147', '#9ACD32', '#FF8C00', '#808000', '#5F9EA0', '#228B22', '#FFD700'];


export enum IssueStatusColors {
ISSUE_TO_DO = '#06D6A0',
  ISSUE_DONE = '#223E6D',
  ISSUE_ASSIGNED = '#505172',
  ISSUE_ENDED = '#367BE2',
  ISSUE_IN_PROGRESS = '#2798F7'
}

export enum PriorityColors {
  PRIORITY_LOW = '#878787',
  PRIORITY_NORMAL = '#367BE2',
  PRIORITY_HIGH = '#FCA036',
  PRIORITY_URGENT = '#F20058'
}


export const getHotelUuid = (): string =>
  providers?.store?.getters['user/getHotelUuid'];

export const getUserRole = (): string =>
 providers?.store?.getters['user/getUserRole'] as string;

export const getUserFullName = (): string =>
  providers?.store?.getters['user/fullUserName'] as string;

export const getUserUuid = (): string =>
  providers?.store?.getters['user/getUserUuid'];

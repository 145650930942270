import { GetterTree }          from 'vuex';
import { RootState, UserRole }                   from '@/store/store.types';
import { Hotel, State, UserNotificationsResponse } from '@/store/modules/user/user.store';

interface UserName {
  name: string
  lastName: string
}

const getters: GetterTree<State, RootState> = {
  userNotificationsSettings(state): UserNotificationsResponse {
    return state.userNotificationsSettings
  },

  fullUserName(state: State): string {
    return `${state.userFirstName || ''} ${state.userLastName || ''}`.trim();
  },
  userName(state): UserName {
    return {
      name: state.userFirstName || '',
      lastName: state.userLastName || ''
    }
  },
  getHotelUuid(state: State): string | null | undefined {
    return state.hotel?.hotelUuid;
  },
  getActiveHotel(state: State): Hotel {
    return state.hotel;
  },
  getUserRole(state: State): UserRole | null {
    return state.role;
  },
  getUserLanguage(state: State): string {
    return state.language;
  },
  getUserUuid(state: State): string {
    return state.uuid
  }
};

export default getters

import { UserRole } from '@/store/store.types';
import actions from '@/store/modules/user/user.actions';
import mutations from '@/store/modules/user/user.mutations';
import getters from '@/store/modules/user/user.getters';
import state from '@/store/modules/user/user.state';

export interface UserNotificationsResponse {
  assignedNotification: boolean;
  controllerNotification: boolean;
  rejectionNotification: boolean;
  emailIssuesDeadlineEnabled: boolean;
  emailIssuesBrieflyToDeadlineEnabled: boolean;
  emailIssuesEnabled: boolean;
  smsNotificationsEnabled: boolean;
  phoneNumber: string;
}

export interface UserNotificationsPayload extends UserNotificationsResponse {
  userUuid: string;
}

export interface Hotel {
  localization: string | null;
  name: string | null;
  hotelUuid: string | null;
}

export interface UserName {
  userLastName: string;
  userFirstName: string;
}

export interface State {
  userFirstName: string | null;
  userLastName: string | null;
  language: string;
  role: UserRole | null;
  hotel: Hotel;
  isController: boolean;
  userNotificationsSettings: UserNotificationsResponse;
  [k: string]: any;
}

const store = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default store;

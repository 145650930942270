import ElNotification from 'element-plus/lib/el-notification';
import { TranslateResult } from 'vue-i18n';

export interface Notification {
  baseParams: {
    duration: number;
    offset: number;
  };
  error: (
    title: TranslateResult,
    message: TranslateResult,
    offset?: number
  ) => void;
  success: (title: TranslateResult, message: TranslateResult) => void;
  warning: (title: TranslateResult, message: TranslateResult) => void;
}

export const Notification: Notification = {
  baseParams: {
    duration: 3000,
    offset: 90,
  },
  warning(title: TranslateResult, message: TranslateResult) {
    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    NotificationContent.ElNotification({
      ...this.baseParams,
      title: title as string,
      message: message as string,
      type: 'warning',
      customClass: 'el-notification--warning',
    });
  },
  error(title: TranslateResult, message: TranslateResult) {
    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    NotificationContent.ElNotification({
      ...this.baseParams,
      title: title as string,
      message: message as string,
      type: 'error',
      customClass: 'el-notification--error',
    });
  },
  success(title: TranslateResult, message: TranslateResult) {
    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    NotificationContent.ElNotification({
      ...this.baseParams,
      title: title as string,
      message: message as string,
      type: 'success',
      customClass: 'el-notification--success',
    });
  },
};

export const NotificationContent = {
  ElNotification,
  Notification,
};

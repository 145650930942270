import { Store }                                                      from 'vuex';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import i18n                                                           from '@/i18n';
import { RootState }                                                  from '@/store/store.types';

export const name = 'vProfile';
export const path = '/profile';

const route = (store: Store<RootState>): RouteRecordRaw => {
  const fetchLocale = async (language?: string): Promise<void> =>
    store.dispatch('i18n/loadTranslations', {
      promise: import(
        /* webpackChunkName: 'views/profile/[request]' */ `./locales/${
          language || i18n.global.locale.value
        }.locale.json`
      ),
      language,
      path: name,
    });

  return {
    name: name,
    path: path,
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/profile/profile.view.vue'),

    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      await fetchLocale(i18n.global.locale.value.toLowerCase())
      next();
    },

    meta: {
      fetchLocale,
    },
  };
};

export default route;

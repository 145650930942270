interface State {
  VIEWS_PATH: string;
}

const state: State = {
  VIEWS_PATH: 'views',
};

const store = {
  namespaced: true,
  state,
};

export default store;


import { defineComponent, onMounted, onUnmounted, reactive, ref, Ref, watch } from 'vue';
import { useApplicationHeader }                                               from '@/components/application-header/use-application-header';
import CNavigationItem                                                        from '@/components/application-header/components/navigation-item.component.vue';

export default defineComponent({
  name: 'CNavigation',
  components: { CNavigationItem },
  setup() {
    const isActive: Ref<boolean> = ref(false);
    const isMounted: Ref<boolean> = ref(false);
    const { activeNavigationElement, activeIcon, navigationElements, setActiveIcon, addRootElement } = useApplicationHeader();

    interface HighlightStyles {
      [ key: string ]: string | number;
    }

    const highlightStyles: HighlightStyles = reactive({
      left: 0,
    });

    const recalculateHighlightStyles = (): void => {
      highlightStyles.left = activeNavigationElement.value ? `${activeNavigationElement.value.offsetLeft}px` : '0';
    };

    watch(() => activeIcon.value, () => {
      recalculateHighlightStyles();
    });

    onMounted(() => {
      isMounted.value = true;
      recalculateHighlightStyles();
      window.addEventListener('resize', recalculateHighlightStyles);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', recalculateHighlightStyles);
    });

    return {
      isMounted,
      isActive,
      highlightStyles,
      navigationElements,
      activeIcon,
      addRootElement,
      setActiveIcon,
    };
  },
});

import {
  FilterConfigurationFilters,
  FilterConfigurationPutPayload,
  Issue,
  IssueEditPayload,
  IssueFiltersItem,
  IssueMainType,
  IssueStatus,
  ModifyIssue
} from '@/views/issues/issues.types';
import { cloneDeep } from 'lodash-es';
import { IssueFilterType } from '@/store/store.types';
import { Deadline, Deadlines } from '@/views/createIssue/createIssue.types';
import { addDays, format } from 'date-fns';

export const modifyIssueToPutPayload = (issue: Issue, type: ModifyIssue, payload: IssueStatus | string): IssueEditPayload => {
  switch (type) {
    case ModifyIssue.STATUS:
      issue.issueStatus = payload as IssueStatus;
      break;
    case ModifyIssue.USER:
      issue.hotelUser.uuid = payload as string;
      break;
    default:
      break;
  }
  const modifiedIssue: Partial<Issue & IssueEditPayload> = Object.assign(issue, {
    locationUuid: issue.location.uuid,
    hotelUserUuid: issue.hotelUser.uuid,
    groupUuid: issue.group.uuid,
    controllerUuid: issue.controller.uuid,
    relatedIssues: [],
    issueTypeUuid: issue.issueType?.uuid || '',
    expectationTypeUuid: issue.expectationType ? issue.expectationType.uuid : null,
    solutionTypeUuid: issue.solutionType ? issue.solutionType.uuid : null
  });

  if (modifiedIssue.mainType === IssueMainType.MAINTENANCE) {
    delete modifiedIssue.expectations;
    delete modifiedIssue.solution;
    delete modifiedIssue.expectationType;
    delete modifiedIssue.solutionType;
  }
  delete modifiedIssue.controller;
  delete modifiedIssue.group;
  delete modifiedIssue.hotelUser;
  delete modifiedIssue.location;
  delete modifiedIssue.uuid;
  delete modifiedIssue.creationDate;
  delete modifiedIssue.issueType;
  delete modifiedIssue.expectationType;
  delete modifiedIssue.solutionType;

  //TODO Check if the modified issue object is valid with edit issue payload
  return modifiedIssue as IssueEditPayload;
}

export const modifyFiltersToPutPayload = (filters: FilterConfigurationFilters) => {
  const configuration = cloneDeep(filters);

  const modifiedConfiguration: Partial<FilterConfigurationFilters> = Object.assign(configuration, {
    locations: configuration.locations,
    assignedUsers: configuration.assignedUsers,
    issueTypes: configuration.issueTypes,
    statuses: configuration.issueStatuses,
    dateRange: {
      min: configuration.min,
      max: configuration.max,
    },
    createdDateRange: {
      min: configuration.createdMin,
      max: configuration.createdMax,
    }

  })

  delete modifiedConfiguration.issueStatuses;
  delete modifiedConfiguration.max;
  delete modifiedConfiguration.min;
  delete modifiedConfiguration.createdMin;
  delete modifiedConfiguration.createdMax;
  return modifiedConfiguration;
}

export const getFiltersIcon = (filter: string): string => {
  switch (filter) {
    case IssueFilterType.ALL:
      return 'iconSquares';
    case IssueFilterType.GROUP:
      return 'iconPeople';
    case IssueFilterType.ASSIGNED:
      return 'IconPerson';
    default:
      return 'IconPerson';
  }
}

export const calcDeadline = (tillDate: Deadlines | null, formatStr: string): string => {
  if (!tillDate) {
    return ''
  }
  let date = new Date();
  if (tillDate.name === Deadline.WEEK) {
    date = addDays(date, 7)
  } else if (tillDate.name === Deadline.TWOWEEKS) {
    date = addDays(date, 14)
  }
  return format(date, formatStr);
}

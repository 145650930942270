import { Composer, useI18n, VueMessageType } from 'vue-i18n';
import { Store, useStore }                   from 'vuex';
import { RootState }                         from '@/store/store.types';
import { Router, useRouter }                 from 'vue-router';

export interface GlobalContexts {
  i18n: Composer<
    Record<string, unknown>,
    Record<string, unknown>,
    Record<string, unknown>,
    VueMessageType
  >;
  router: Router;
  store: Store<RootState>;
}

export const useGlobals = (): GlobalContexts => ({
  i18n: useI18n() as Composer<
    Record<string, unknown>,
    Record<string, unknown>,
    Record<string, unknown>,
    VueMessageType
  >,
  router: useRouter() as Router,
  store: useStore() as Store<RootState>,
});

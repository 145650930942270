
import { computed, ComputedRef, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { NavigationElement }                                                     from '@/components/application-header/use-application-header';
import IconMaintenance                                                           from '@/components/icons/icon-maintenance.component.vue';
import IconInsurance                                                             from '@/components/icons/icon-insurance.component.vue';
import IconVisibility                                                            from '@/components/icons/icon-visibility.component.vue';
import IconControl                                                               from '@/components/icons/icon-control.component.vue';
import { useGlobalTranslations }                                                 from '@/hooks/use-translations';
import { useGlobals }                                                            from '@/hooks/use-globals';

export default defineComponent({
  name: 'CNavigationItem',
  components: { IconVisibility, IconInsurance, IconMaintenance, IconControl },
  props: {
    element: {
      type: Object as PropType<NavigationElement>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: [ 'add-root-element' ],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const isController: ComputedRef<boolean> = computed(() => store.state.user.isController);
    const root: Ref<HTMLElement | null> = ref(null);
    onMounted(() => {
      emit('add-root-element', root);
    });
    return {
      isController,
      useGlobalTranslations,
      root,
    };
  },
});

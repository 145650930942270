
import { defineComponent }         from 'vue';
import { Listbox, ListboxOptions } from '@headlessui/vue';
import cExpandButton               from '@/components/common/expand-button/expand-button.component.vue';

export default defineComponent({
  name: 'CDropdown',
  components: {
    cExpandButton,
    Listbox,
    ListboxOptions,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    openedIconClass: {
      type: String,
      default: '',
    },
    closedIconClass: {
      type: String,
      default: '',
    },
  },
});

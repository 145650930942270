import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "application",
  class: "c-application max-h-screen h-screen min-w-full flex sm:block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_application_header = _resolveComponent("c-application-header")
  const _component_c_left_side_bar = _resolveComponent("c-left-side-bar")
  const _component_c_breadcrumbs = _resolveComponent("c-breadcrumbs")
  const _component_c_admin_navbar = _resolveComponent("c-admin-navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_c_main_content = _resolveComponent("c-main-content")

  return (_ctx.isRouterReady && _ctx.isContentReady)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.showHeader)
          ? (_openBlock(), _createBlock(_component_c_application_header, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.showHeader)
          ? (_openBlock(), _createBlock(_component_c_left_side_bar, {
              key: 1,
              class: "sm:hidden min-w-leftSideBar"
            }))
          : _createCommentVNode("", true),
        (_ctx.showAdministration)
          ? (_openBlock(), _createBlock(_component_c_main_content, {
              key: 2,
              class: "grid grid-cols-admin-layout grid-rows-admin-layout h-screen md:max-h-mainContent overflow-hidden"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_c_breadcrumbs, { class: "row-start-1 col-start-1 col-end-3 row-end-2" }),
                _createVNode(_component_c_admin_navbar, { class: "row-start-2 col-start-1 col-end-2 row-end-3" }),
                _createVNode(_component_router_view, { class: "col-start-2 col-end-3 row-start-2 row-end-3" })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 3 }))
      ]))
    : _createCommentVNode("", true)
}
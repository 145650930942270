import { GetterTree } from 'vuex';
import { IssueMainType, RootState } from '@/store/store.types';
import { State } from '@/store/modules/issues/issues.store';
import {
  ColouredPriority,
  ColouredStatus,
  IssueFiltersItem,
  IssueStatus,
  ListIssue, SelectedFilters,
  SpecificFilters
} from '@/views/issues/issues.types';
import { cloneDeep } from 'lodash-es';
import i18n from '@/i18n';
import { getIssueDetailsContent } from '@/store/modules/issues/issues.helpers';

export const getters: GetterTree<State, RootState> = {

  controllerTableData(state) {
    return state.issues.map((issue) => ({
      uuid: issue.uuid,
      priority: [state.priorityList.find(
        (element: ColouredPriority) => issue.priority === element.name
      )],
      name: issue.name,
      status: [state.statusList.find(
        (element: ColouredStatus) => issue.status === element.name
      )],
      created: issue.creationDate,
      deadline: issue.deadline,
      location: issue.location.name,
      issueNumber: issue.issueNumber,
      actions: [
        { icon: 'icon-discard', name: 'decline' },
        { icon: 'icon-accept', name: 'accept' },
      ],
    }))
  },
  issuesTableData(state) {
    if (state.issues.length) {
      const mappedIssues = cloneDeep(state.issues);
      mappedIssues.forEach((issue: any) => {
        issue.location = issue.location.name;
        issue.hotelUser = issue.hotelUser.name ? `${issue.hotelUser.name} ${issue.hotelUser.lastName}` : '';
        issue.group = issue.group.name;
        issue.issueStatus = state.statusList.filter((el: ColouredStatus) => el.name === issue.status).map((el: ColouredStatus) => ({
          name: i18n.global.t(`vIssues.labels.${el.name}`),
          color: el.color
        }));
        issue.deadline = issue.deadline || '';
        issue.priority = state.priorityList.filter((el: ColouredPriority) => el.name === issue.priority);
      });
      return mappedIssues;
    }
    return [];
  },
  getIssueInformation(state) {
    const selectedIssue = state.selectedIssue;
    return selectedIssue ? getIssueDetailsContent(selectedIssue) : null
  },
  getIssueDetails(state) {
    const issue = state.selectedIssue;
    return {
      isObserved: issue?.isObserved,
      uuid: issue?.uuid,
      status: issue?.issueStatus,
      priority: state.priorityList.find((el: ColouredPriority) => el.name === issue?.priority),
      title: issue?.name,
      number: issue?.issueNumber,
      controllerUuid: issue?.controller.uuid,
    }
  },
  issuesToDo(state) {
    return state.issues.filter((issue: ListIssue) => issue.status === IssueStatus.TODO || issue.status === IssueStatus.NEW
            || issue.status === IssueStatus.ASSIGNED || issue.status === null)
  },
  issuesInProgress(state) {
    return state.issues.filter((issue: ListIssue) => issue.status === IssueStatus.IN_PROGRESS)
  },
  issuesFinished(state) {
    return state.issues.filter((issue: ListIssue) => issue.status === IssueStatus.DONE)
  },
  amountOfSelectedFilters(state) {
    const filters = state.selectedIssueFilters
    let amount = 0;
    const entriesOfFilters = Object.entries(filters)
    entriesOfFilters.forEach(([key, value]) => {
      switch (key) {
        case SpecificFilters.DATE_RANGE:
        case SpecificFilters.CREATED_DATE_RANGE: {
          amount += value.min ? 1 : 0
          amount += value.max ? 1 : 0
          break;
        }
        case SpecificFilters.OVERDUE: {
          if (value) {
            amount += 1;
          }
          break;
        }
        case SpecificFilters.TYPE: {
          break;
        }
        default: {
          if (value) {
            amount += value.length;
          }
        }
      }
    });
    return amount;
  },
  selectedFiltersToDisplay(state) {
    const modifiedFilters: Partial<SelectedFilters> = cloneDeep(state.selectedIssueFilters)
    modifiedFilters.filterType && delete modifiedFilters.filterType
    return modifiedFilters
  },

  filterConfigLabels(state) {
    return state.allFiltersConfigurations.map(({ name, configUuid }) => (
      {
        name,
        value: configUuid
      })
    )
  },

  getEditFormSelectedIssue(state) {
    const selectedIssue = state.selectedIssue;
    if (!selectedIssue) return
    const { location, issueType, group, controller, expectationType, solutionType, hotelUser, relatedIssues, ...options } = selectedIssue;
    return {
      ...options,
      locationUuid: location.uuid,
      groupUuid: group.uuid,
      controllerUuid: controller.uuid,
      issueTypeUuid: issueType.uuid,
      hotelUserUuid: hotelUser.uuid,
      relatedIssues: relatedIssues?.length ? relatedIssues : [],
      expectationTypeUuid: expectationType?.uuid ? expectationType.uuid : null,
      solutionTypeUuid: solutionType?.uuid ? solutionType.uuid : null
    }
  },
  issueFilters(state) {
    const filters = state.filtersConfiguration.filters;

    const modifiedGroups: string[] = (filters.otherGroups as Array<IssueFiltersItem | string>).map((group: string | IssueFiltersItem) => {
      return typeof group === 'string'
        ? group
        : group.uuid
    })

    return {
      issueStatuses: filters.issueStatuses,
      priorities: filters.priorities,
      issueTypes: filters.issueTypes,
      otherGroups: modifiedGroups,
      guests: filters.guests,
      locations: filters.locations,
      assignedUsers: filters.assignedUsers,
      min: filters.min,
      max: filters.max,
      createdMin: filters.createdMin,
      createdMax: filters.max,
      deadlineOver: filters.deadlineOver
    }
  },
  issuesOptions(state) {
    const options = cloneDeep(state.options);
    return {
      guests: options.guests.map(guest => ({ value: guest, name: guest })),
      issueTypes: options.issueTypes.map(({ uuid, name }) => ({ value: uuid, name })),
      locations: options.locations.map(({ uuid, name }) => ({ value: uuid, name })),
      assignedUsers: options.users.map(({ uuid, name }) => ({ value: uuid, name })),
      createdByUsers: options.users.map(({ uuid, name }) => ({ value: uuid, name })),
      groups: options.groups
    }
  },
  editIssueForm(state) {
    if (!state.selectedIssue) return
    const options = state.options;
    const currentIssueType = state.selectedIssue.mainType


    const { solutionType, expectationType } = state
    return {
      issueTypes: options.issueTypes.map(({ uuid, name }) => ({ value: uuid, name })),
      locations: options.locations.map(({ uuid, name }) => ({ value: uuid, name })),
      assignedUsers: options.users.map(({ uuid, name }) => ({ value: uuid, name })),
      groups: options.groups.map(({ uuid, name }) => ({ value: uuid, name })),
      ...(currentIssueType === IssueMainType.CARE && {
        expectationType: expectationType.map(({ uuid, name }) => ({
          value: uuid,
          name
        }))
      }),
      ...(currentIssueType === IssueMainType.CARE && {
        solutionType: solutionType.map(({ uuid, name }) => ({
          value: uuid,
          name
        }))
      }),
    }
  }
}

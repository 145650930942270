
import { defineComponent, PropType } from 'vue';
import { OptionDropdownElement }     from '@/components/application-header/use-application-header';
import cDropdown                     from '@/components/common/dropdown/dropdown.component.vue';
import cDropdownItem                 from '@/components/common/dropdown-item/dropdown-item.component.vue';

export default defineComponent({
  name: 'COptionsDropdown',
  components: {
    cDropdownItem,
    cDropdown,
  },
  props: {
    items: {
      type: Array as PropType<OptionDropdownElement[]>,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    closedIconClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    openedIconClass: {
      type: String,
      default: ''
    },
    separateLastItem: {
      type: Boolean,
      default: false
    }
  }
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex items-center py-f13" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_breadcrumbs_item = _resolveComponent("c-breadcrumbs-item")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (path, index) => {
      return (_openBlock(), _createBlock(_component_c_breadcrumbs_item, {
        key: index,
        index: index,
        "number-of-elements": _ctx.breadcrumbs.length - 1,
        path: path
      }, null, 8, ["index", "number-of-elements", "path"]))
    }), 128))
  ]))
}
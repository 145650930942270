import { I18n } from 'vue-i18n';
import { Store } from 'vuex';
import { Router } from 'vue-router';
import { RootState } from '@/store/store.types';

export interface Providers {
  i18n: I18n<
    Record<string, unknown>,
    Record<string, unknown>,
    Record<string, unknown>,
    false
  > | null;
  router: Router | null;
  store: Store<RootState> | null;
}

const providers: Providers = {
  store: null,
  i18n: null,
  router: null,
};

export default providers;

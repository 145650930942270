import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-main-navigation flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_navigation_item = _resolveComponent("c-navigation-item")

  return (_openBlock(), _createBlock("nav", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.navigationElements, (element) => {
      return (_openBlock(), _createBlock(_component_c_navigation_item, {
        key: element,
        element: element,
        "is-active": element.icon === _ctx.activeIcon,
        onAddRootElement: _cache[1] || (_cache[1] = ($event: any) => ([_ctx.addRootElement($event), _ctx.setActiveIcon()]))
      }, null, 8, ["element", "is-active"]))
    }), 128)),
    _withDirectives(_createVNode("div", {
      class: "absolute ml-0 bottom-0 h-1 w-44 bg-primary-500 transform duration-200",
      style: _ctx.highlightStyles
    }, null, 4), [
      [_vShow, _ctx.activeIcon.length]
    ])
  ]))
}

import { computed, defineComponent, Ref } from 'vue';
import { useGlobals }                     from '@/hooks/use-globals';
import cBreadcrumbsItem                   from '@/components/common/breadcrumbs-item/breadcrumbs-item.component.vue';

export default defineComponent({
  name: 'CBreadcrumbs',
  components: { cBreadcrumbsItem },
  setup() {
    const { router } = useGlobals();
    const breadcrumbs: Ref<string[]> = computed(() =>
      router.currentRoute.value.path
        .split('/')
        .reduce((acc: string[], curr: string) => [ ...acc, curr ], []),
    );
    return {
      breadcrumbs,
    };
  },
});

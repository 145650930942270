<template>
  <div class="c-circle-icon-wrapper rounded-full flex items-center w-12 h-12 cursor-pointer"
       :class="[active? `bg-${activeColor}` : 'bg-gray-icon',
                biggerIcon?'sm:w-20 sm:h-20' : 'sm:w-12 sm:h-12']"
       @click="$emit('update:activeIcon', component), $emit('updateParams',component)">
    <component :is="component"
               class="mx-auto w-7 h-7 sm:w-10 sm:h-10 text-white" />
  </div>
  <!--  save from purge-->
  <!--  <div class="bg-redLight bg-violetLight bg-yellow"></div>-->
</template>

<script>
import { defineComponent } from 'vue';
import IconMaintenance     from '@/components/icons/icon-maintenance.component.vue';
import IconVisibility      from '@/components/icons/icon-visibility.component.vue';
import IconInsurance       from '@/components/icons/icon-insurance.component.vue';
import IconPerson          from '@/components/icons/icon-person.component.vue';
import IconPeople          from '@/components/icons/icon-people.component.vue';
import IconSquares         from '@/components/icons/icon-squares.component.vue';
import IconControl         from '@/components/icons/icon-control.component.vue';

export default defineComponent({
  name: 'CCircleIconWrapper',
  components: {
    IconMaintenance,
    IconVisibility,
    IconInsurance,
    IconPerson,
    IconPeople,
    IconSquares,
    IconControl
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: '',
    },
    activeIcon: {
      type: String,
      default: 'IconMaintenance',
    },
    biggerIcon: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
      default: 'primary-600',
    },

  },
  emits: [ 'update:activeIcon', 'updateParams' ],
});
</script>



import { StoreOptions } from 'vuex';
import views from '@/store/modules/views/views.store';
import i18n from '@/store/modules/i18n/i18n.store';
import user                     from '@/store/modules/user/user.store';
import issues               from '@/store/modules/issues/issues.store'
import { applicationRootState } from '@/store/store.state';
import { applicationRootMutations } from '@/store/store.mutations';

const storeConfig: StoreOptions<any> = {
  modules: {
    views,
    i18n,
    user,
    issues
  },
  state: applicationRootState,
  mutations: applicationRootMutations,
  actions: {},
  getters: {},
};

export default storeConfig;

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.element.name === 'Controller' ? _ctx.isController : true )
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        ref: "root",
        class: "flex flex-col items-center justify-between h-header p-f21 cursor-pointer group w-44",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.element.onClick(_ctx.root)))
      }, [
        _createVNode("div", {
          class: ["flex items-center justify-center rounded-full w-12 h-12 group-hover:bg-primary-500", _ctx.isActive ? 'bg-primary-500' : 'bg-gray-icon']
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.element.icon), { class: "w-8 h-8 text-white" }))
        ], 2),
        _createVNode("span", {
          class: ["text-10 group-hover:text-primary-500", _ctx.isActive && 'text-primary-500' ]
        }, _toDisplayString(_ctx.useGlobalTranslations(`${_ctx.element.name}`)), 3)
      ], 512))
    : _createCommentVNode("", true)
}
import { State } from '@/store/modules/user/user.store';
import { generateDefaultHotel } from '@/store/modules/user/user.helpers';

export const state: State = {
  userFirstName: '',
  userLastName: '',
  language: 'en',
  role: null,
  hotel: generateDefaultHotel(),
  isController: false,
  uuid: '',
  userNotificationsSettings: {
    assignedNotification: true,
    controllerNotification: true,
    rejectionNotification: true,
    emailIssuesDeadlineEnabled: true,
    emailIssuesBrieflyToDeadlineEnabled: true,
    emailIssuesEnabled: true,
    smsNotificationsEnabled: true,
    phoneNumber: '',
  },
};

export default state;

import { ActionTree }                         from 'vuex';
import { RootState, UserDetailsResponse }                                                       from '@/store/store.types';
import { changeUserName, changeUserNotifications, getUserData, getUserNotifications, logoutUser } from '@/service/user.service';
import { Notification }                                                                         from '@/components/notification/notification';
import i18n                                 from '@/i18n';
import { GLOBAL }                             from '@/helpers/variables';
import { updateLanguage, updatePassword } from '@/views/administration/administration.service';
import { State, UserNotificationsPayload }      from '@/store/modules/user/user.store';

const actions: ActionTree<State, RootState> = {
  async getUserData({ commit, dispatch }): Promise<void> {
    try {
      const { data }: { data: UserDetailsResponse } = await getUserData();
      window.localStorage.setItem('hotelUuid', data.hotel.hotelUuid as string);
      commit('setUserData', data);
      i18n.global.locale.value = data.language
      dispatch('getUserNotificationsSettings', data.uuid)
    } catch (e) {
      throw Error(e);
    }
  },

  async logoutUser({ commit }): Promise<void> {
    try {
      await logoutUser();
      commit('user/setDefaultStateValue');
      window.localStorage.removeItem('hotelUuid');
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw Error(e);
    }
  },
  async changeUserPassword(context, { userUuid, password, repeatPassword }: {userUuid: string, password: string, repeatPassword: string}): Promise<void> {
    try {
      await updatePassword('users', userUuid, {
        password,
        repeatPassword
      })
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vProfile.notifications.success.password')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async changeLanguage(context, { userUuid, language }: {userUuid: string, language: string}): Promise<void> {
    try {
      await updateLanguage('users', userUuid, { language })
      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vProfile.notifications.success.language')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }
  },
  async editUserName({ commit }, { userUuid, name, lastName }: {userUuid: string, name: string, lastName: string}) {
    try {
      await changeUserName( userUuid, {
        name,
        lastName
      })

      commit('setUserFullName', {
        name, lastName
      })

      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vProfile.notifications.success.userName')
      );
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
      throw e.data;
    }

  },

  async  editUserNotification({ commit }, { userUuid, ...payload }: UserNotificationsPayload) {
    try {
      await changeUserNotifications(userUuid, payload)
      commit('setUserNotificationsSettings', payload)

      Notification.success(
        i18n.global.t(`${GLOBAL}.success.success`),
        i18n.global.t('vProfile.notifications.success.notifications')
      );
    }
    catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
    }
  },

  async getUserNotificationsSettings({ commit }, userUuid: string) {
    try {
      const { data } = await getUserNotifications(userUuid);
      commit('setUserNotificationsSettings', data)
    }
    catch (e) {
      
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${GLOBAL}.errors.${e.data}`)
      );
    }
  }
};

export default actions

import axios from 'axios';
import providers from '@/providers';
import * as Qs from 'qs';
import { name as vLogin } from '@/views/login/login.route';

export enum RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export enum RESPONSE_DATA {
  EMAIL_NOT_AVAILABLE = 'EMAIL_IS_NOT_AVAILABLE'
}

export const useAxiosInterceptors = (): void => {
  axios.interceptors.request.use(
    (config) => {
      config.paramsSerializer = (params) =>
        Qs.stringify(params, { arrayFormat: 'repeat' });
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    async (config) => {

      return config
    },
    async (error) => {

      if (
        error.response &&
        [RESPONSE_STATUS.UNAUTHORIZED, RESPONSE_STATUS.FORBIDDEN].includes(
          error.response.status
        )
      ) {
        await providers.router?.push({ name: vLogin });
      }

      return Promise.reject(error.response);
    }
  );
};

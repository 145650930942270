
import { defineComponent } from 'vue';
import { ListboxButton }   from '@headlessui/vue';
import iconArrowDown       from '@/components/icons/icon-arrow-down.component.vue';
import iconCogwheel        from '@/components/icons/icon-cogwheel.component.vue';
import iconMore            from '@/components/icons/icon-more.component.vue';

export default defineComponent({
  name: 'CExpandButton',
  components: {
    ListboxButton,
    iconArrowDown,
    iconCogwheel,
    iconMore,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    openedClass: {
      type: String,
      default: '',
    },
    closedClass: {
      type: String,
      default: '',
    },
  },
});

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-dropdown__options relative top-8 z-9999" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_expand_button = _resolveComponent("c-expand-button")
  const _component_listbox_options = _resolveComponent("listbox-options")
  const _component_listbox = _resolveComponent("listbox")

  return (_openBlock(), _createBlock(_component_listbox, { as: "div" }, {
    default: _withCtx(() => [
      _createVNode(_component_c_expand_button, {
        "closed-class": _ctx.closedIconClass,
        icon: _ctx.icon,
        "icon-class": _ctx.iconClass,
        "opened-class": _ctx.openedIconClass
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _: 3
      }, 8, ["closed-class", "icon", "icon-class", "opened-class"]),
      _createVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-300",
          "enter-from-class": "opacity-0 transform scale-y-0",
          "enter-to-class": "opacity-100 transform scale-y-100",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_listbox_options, {
              class: ["c-dropdown__options__list absolute z-9999 min-w-full rounded-md bg-white cursor-pointer\n          shadow-around max-h-56 overflow-auto focus:outline-none sm:text-sm scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-primary-300", _ctx.icon ? 'right-0' : 'left-0']
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["class"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}

import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useGlobals }                                       from '@/hooks/use-globals';
import { Notification }                                     from '@/components/notification/notification';
import { useGlobalTranslations }                            from '@/hooks/use-translations';
import cApplicationHeader                                   from '@/components/application-header/application-header.component.vue';
import cAdminNavbar                                         from '@/components/administration-navigation/administration-navigation.component.vue';
import cBreadcrumbs                                         from '@/components/common/breadcrumbs/breadcrumbs.component.vue';
import cMainContent                                         from '@/components/main-content/main-content.component.vue';
import CLeftSideBar                                         from '@/components/left-side-bar/left-side-bar.component.vue';

export default defineComponent({
  name: 'Root',
  components: {
    cMainContent,
    cBreadcrumbs,
    cAdminNavbar,
    cApplicationHeader,
    CLeftSideBar,
  },
  setup() {
    const {
      router,
      store,
    } = useGlobals();
    const isContentReady: Ref<boolean> = ref(false);
    const isRouterReady: Ref<boolean> = ref(false);
    const showHeader: ComputedRef<boolean> = computed(() => !router.currentRoute.value.meta.hideApplicationHeader);
    const showAdministration: ComputedRef<boolean> = computed(() => !!router.currentRoute.value.meta.showAdministrationNavigation);

    const loadInitialAssets = async (): Promise<void> => {
      try {
        await router.isReady();
        isRouterReady.value = true;
        await store.dispatch('i18n/initialize');
        isContentReady.value = true;
      } catch (e) {
        Notification.error(useGlobalTranslations('errors.error'), useGlobalTranslations('errors.somethingWentWrong'),
        );
        throw e;
      }
    };

    loadInitialAssets();

    return {
      isRouterReady,
      isContentReady,
      showHeader,
      showAdministration,
    };
  },
});

import { Module, Store } from 'vuex';
import { cloneDeep } from 'lodash-es';
import { RootState } from '@/store/store.types';

export const registerNewModule = (
  store: Store<any>,
  path: string[],
  data: Module<any, RootState>
): void => {
  store.registerModule(path, cloneDeep(data));
};

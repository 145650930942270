// noinspection JSUnusedGlobalSymbols

import { LocaleMessageObject, LocaleMessageValue, VueMessageType } from 'vue-i18n';
import { ActionTree }                                              from 'vuex';
import i18n                                                        from '@/i18n';
import { AddBreadcrumbTranslationPayload, RootState }              from '@/store/store.types';
import { RouteLocationNormalized }                                 from 'vue-router';
import { cloneDeep }                                               from 'lodash-es';

export interface State {
  GLOBAL_PATH: string;
}

const state: State = {
  GLOBAL_PATH: process.env.VUE_APP_I18N_GLOBAL_PATH as string,
};

export interface Messages {
  default: LocaleMessageObject;
}

interface TranslationsProperties {
  promise: Promise<Messages>;
  path: string;
  language?: string;
}

const actions: ActionTree<State, RootState> = {
  loadTranslations: async (
    context,
    { promise, path }: TranslationsProperties
  ): Promise<void> => {
    const activeLanguage: string = i18n.global.locale.value;
    try {
      const messages: Messages = await promise;
      const messagesWithPath: LocaleMessageObject = path
        ? { [path]: messages.default }
        : messages.default;
      i18n.global.mergeLocaleMessage(
        activeLanguage,
        messagesWithPath as LocaleMessageObject<VueMessageType>
      );
    } catch (e) {
      throw new Error(e);
    }
  },

  loadGlobalLocale: async (
    { state, dispatch },
    language: string
  ): Promise<void | null> => {
    return dispatch('loadTranslations', {
      promise: import(
        /* webpackChunkName: 'locales/[request]' */ `@/root/locales/${language}.locale.json`
      ),
      path: state.GLOBAL_PATH,
      language,
    });
  },

  changeDocumentTitle: async (
    _context,
    to: RouteLocationNormalized
  ): Promise<void> => {
    document.title = i18n.global.t(`${String(to.name)}.meta.title`).toString();
  },

  addBreadcrumbTranslationsInSpecifiedModule: async (
    _context,
    payload: AddBreadcrumbTranslationPayload
  ): Promise<void> => {
    const activeLanguage: string = i18n.global.locale.value;
    try {
      const currentMessages = cloneDeep(
        i18n.global.messages.value.en[`${payload.module}`]
      ) as LocaleMessageObject;
      if (currentMessages) {
        const currentMessagesMeta = cloneDeep(
          currentMessages.meta
        ) as LocaleMessageObject;
        const translation: LocaleMessageValue = {
          [payload.key]: payload.translation,
        };
        Object.assign(currentMessagesMeta.breadcrumb, translation);

        const messages: LocaleMessageObject = {
          [payload.module]: {
            ...currentMessages,
            meta: {
              ...currentMessagesMeta,
            },
          },
        };
        i18n.global.mergeLocaleMessage(
          activeLanguage,
          messages as LocaleMessageObject<VueMessageType>
        );
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  initialize: async ({ dispatch }): Promise<void | null> => {
    await dispatch('loadGlobalLocale', i18n.global.locale.value.toLowerCase());
  },
};

const store = {
  namespaced: true,
  state,
  actions,
};

export default store;

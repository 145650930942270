
import { defineComponent }                        from 'vue';
import { useGlobals }                             from '@/hooks/use-globals';
import iconArrowDown                              from '@/components/icons/icon-arrow-down.component.vue';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';

export default defineComponent({
  name: 'CBreadcrumbsItem',
  components: { iconArrowDown },
  props: {
    path: {
      required: true,
      type: String,
    },
    index: {
      type: Number,
      default: 0,
    },
    numberOfElements: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { router } = useGlobals();
    const navigateTo = (path: string): string => {
      const fullPathDivided: string[] = router.currentRoute.value.path.split(
        `/${path}`,
      );
      return `${fullPathDivided[ 0 ]}/${path}`;
    };

    return {
      useGlobalTranslations,
      useTranslations,
      navigateTo,
    };
  },
});

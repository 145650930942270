import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-hotel-label flex flex-col items-start" }
const _hoisted_2 = { class: "text-gray-600 text-10" }
const _hoisted_3 = { class: "text-gray-900 text-14 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("span", _hoisted_2, _toDisplayString(_ctx.hotel.localization), 1),
    _createVNode("span", _hoisted_3, _toDisplayString(_ctx.hotel.name), 1)
  ]))
}
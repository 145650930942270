import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';

const DEFAULT_LOCALE = 'en';
const ACTIVE_LOCALE = process.env.VUE_APP_I18N_LOCALE || DEFAULT_LOCALE;

const GLOBAL_DEFAULT_TRANSLATIONS: LocaleMessages<VueMessageType> = {
  [ACTIVE_LOCALE]: {
    __global__: require(`./root/locales/${ACTIVE_LOCALE}.locale.json`),
  },
};

const i18n = createI18n({
  legacy: false,
  locale: ACTIVE_LOCALE,
  datetimeFormats: {},
  numberFormats: {},
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: GLOBAL_DEFAULT_TRANSLATIONS,
});

export default i18n;

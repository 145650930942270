import { Store } from 'vuex';
import { RootState } from '@/store/store.types';
import providers from '@/providers';

const checkForEmptyParameters = (args: {
  store?: Store<RootState>;
  name?: string;
}): void => {
  if (args.name == null) {
    throw new Error('Please provide correct parameters to this function');
  }
};

export const getRegisterViewStorePath = (name: string): string[] => {
  checkForEmptyParameters({ name });

  if (providers.store) {
    return [(providers.store as Store<RootState>).state.views.VIEWS_PATH, name];
  }

  return [name];
};

export const getViewStorePath = (name: string): string => {
  checkForEmptyParameters({ name });

  if (providers.store) {
    return [
      (providers.store as Store<RootState>).state.views.VIEWS_PATH,
      name,
    ].join('/');
  }
  return name;
};

import { Store }                                                         from 'vuex';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, } from 'vue-router';
import { registerNewModule }                                             from '@/router/router.helpers';
import { getRegisterViewStorePath }                                      from '@/store/store.helpers';
import i18n                                                              from '@/i18n';
import { RootState }                                                     from '@/store/store.types';

const route = (
  store: Store<RootState>,
  name: string,
  path: string,
  component: string
): RouteRecordRaw => {
  const fetchLocale = async (language?: string): Promise<void> =>
    store.dispatch('i18n/loadTranslations', {
      promise: import(
        /* webpackChunkName: 'views/administration/[request]' */ `./views/${component}/locales/${
          language || i18n.global.locale.value
        }.locale.json`
      ),
      language,
      path: name,
    });

  return {
    name: name,
    path: path,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ `@/views/administration/views/${component}/${component}.view.vue`
      ),
    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const STORE_PATH: string[] = getRegisterViewStorePath(name);
      const storeExists: boolean = store.hasModule(STORE_PATH);
      const storePromise: () => Promise<void> = async () =>
        import(
          /* webpackChunkName: 'administration' */ `@/views/administration/views/${component}/store/${component}.store`
        ).then((data) => {
          registerNewModule(store, STORE_PATH, data.default);
        });
      const promises: Promise<any>[] = [
        ...(!storeExists ? [storePromise()] : []),
        fetchLocale(i18n.global.locale.value.toLowerCase()),
      ];
      await Promise.all(promises);

      const pagination: string[] = [name + 'Table'];
      store.commit('setPagination', pagination);

      next();
    },
    meta: {
      fetchLocale,
      showAdministrationNavigation: true,
    },
  };
};

export default route;

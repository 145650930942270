
import { defineComponent, PropType } from 'vue';
import { Hotel }                     from '@/store/modules/user/user.store';

export default defineComponent({
  name: 'CHotelLabel',
  props: {
    hotel: {
      type: Object as PropType<Hotel>,
      required: true,
    },
  },
});

import { GlobalContexts, useGlobals } from '@/hooks/use-globals';
import { TranslateResult }            from 'vue-i18n';
import { GLOBAL }                     from '@/helpers/variables';

export const useTranslations = (
  translation: string,
  arg?: string[]
): TranslateResult => {
  const { i18n, router }: GlobalContexts = useGlobals();
  const { t } = i18n;
  const translatePath: string = router.currentRoute.value.name as string;
  return t(`${translatePath}.${translation}`, arg || ['']);
};

export const useGlobalTranslations = (
  translation: string,
  arg?: string[]
): TranslateResult => {
  const { i18n }: GlobalContexts = useGlobals();
  const { t } = i18n;
  return t(`${GLOBAL}.${translation}`, arg || ['']);
};

import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_listbox_button = _resolveComponent("listbox-button")
  const _component_icon_arrow_down = _resolveComponent("icon-arrow-down")

  return (_ctx.icon)
    ? (_openBlock(), _createBlock(_component_listbox_button, {
        key: 0,
        class: "c-expand-button__icon group relative w-full rounded-full py-f3 cursor-pointer flex justify-end items-center focus:outline-none text-16"
      }, {
        default: _withCtx(({ open }) => [
          _renderSlot(_ctx.$slots, "default"),
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
            class: `${_ctx.iconClass} ${open ? _ctx.openedClass : _ctx.closedClass}`
          }, null, 8, ["class"]))
        ]),
        _: 3
      }))
    : (_openBlock(), _createBlock(_component_listbox_button, {
        key: 1,
        class: "c-expand-button group min-w-max hover:bg-gray-200 relative w-full py-f3 cursor-pointer flex justify-between items-center focus:outline-none text-16"
      }, {
        default: _withCtx(({ open }) => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_icon_arrow_down, {
            class: ["h-3 w-3 cursor-pointer mx-2 group-hover:text-primary-400 transform transition ease-in", open ? `text-primary-400 rotate-180 + ${_ctx.openedClass}` : `group-hover:translate-y-0.5 + ${_ctx.closedClass}`]
          }, null, 8, ["class"])
        ]),
        _: 3
      }))
}
import { Deadline } from '@/views/createIssue/createIssue.types';
import { FilterConfigurationFilters, IssuePriority, IssueStatus, SelectedFilters } from '@/views/issues/issues.types';
import { MainType } from '@/types';
import { IssueStatusColors, PriorityColors } from '@/helpers/variables';

export const START_ISSUE = 'startIssue'
export const END_ISSUE = 'endIssue'
export const RESTORE = 'restore'
export const ASSIGN_TO_ME = 'assignToMe'
export const USERS_PAGINATION = 'userListPagination'
export const ISSUES_PAGINATION = 'issuesListPagination'
export const priorities = ['LOW', 'NORMAL', 'HIGH', 'URGENT'];
export const statuses = ['NEW', 'ASSIGNED', 'IN_PROGRESS', 'DONE', 'ENDED'];
export const deadlines = [Deadline.TODAY, Deadline.WEEK, Deadline.TWOWEEKS];
export const priority = 'priority';
export const status = 'status';
export const type = 'type';
export const related = 'related';
export const initialFiltersConfiguration: FilterConfigurationFilters = {
  issueFilterConfigurationUuid: '',
  priorities: [],
  issueStatuses: [],
  deadlineOver: false,
  issueTypes: [],
  otherGroups: [],
  assignedUsers: [],
  createdByUsers: [],
  locations: [],
  guests: [],
  min: null,
  max: null,
  createdMin: null,
  createdMax: null,
  filterType: MainType.MAINTENANCE
};

export const initialFilterState: SelectedFilters = {
  assignedUsers: [],
  createdByUsers: [],
  deadlineOver: false,
  filterType: MainType.MAINTENANCE,
  guests: [],
  issueFilterConfigurationUuid: '',
  statuses: [],
  createdDateRange: {
    min: null,
    max: null
  },
  dateRange: {
    max: null,
    min: null
  },
  issueTypes: [],
  locations: [],
  otherGroups: [],
  priorities: []
}
export const statusList = [
  { name: IssueStatus.NEW, color: IssueStatusColors.ISSUE_TO_DO, value: IssueStatus.NEW },
  { name: IssueStatus.DONE, color: IssueStatusColors.ISSUE_DONE, value: IssueStatus.DONE },
  { name: IssueStatus.ASSIGNED, color: IssueStatusColors.ISSUE_ASSIGNED, value: IssueStatus.ASSIGNED },
  { name: IssueStatus.ENDED, color: IssueStatusColors.ISSUE_ENDED, value: IssueStatus.ENDED },
  { name: IssueStatus.IN_PROGRESS, color: IssueStatusColors.ISSUE_IN_PROGRESS, value: IssueStatus.IN_PROGRESS },
];
export const priorityList = [
  { name: IssuePriority.LOW, color: PriorityColors.PRIORITY_LOW, value: IssuePriority.LOW },
  { name: IssuePriority.NORMAL, color: PriorityColors.PRIORITY_NORMAL, value: IssuePriority.NORMAL },
  { name: IssuePriority.HIGH, color: PriorityColors.PRIORITY_HIGH, value: IssuePriority.HIGH },
  { name: IssuePriority.URGENT, color: PriorityColors.PRIORITY_URGENT, value: IssuePriority.URGENT },
];



import { Store } from 'vuex';
import { RouteRecordRaw } from 'vue-router';
import { RootState }      from '@/store/store.types';
import vController      from '@/views/controller/controller.route'
import vLogin from '@/views/login/login.route';
import cResetPassword from '@/views/resetPassword/reset-password.route';
import cSetPassword from '@/views/setPassword/set-password.route'
import vAdministration from '@/views/administration/administration.route';
import vDashboard from '@/views/dashboard/dashboard.route';
import vCreateIssue from '@/views/createIssue/createIssue.route';
import vIssue       from '@/views/issues/issues.route';
import vProfile from '@/views/profile/profile.route'
import axios from 'axios';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';

const administrationRoutes = [
  {
    name: 'users',
    path: '/administration/users',
    component: 'administration-users',
  },
  {
    name: 'groups',
    path: '/administration/groups',
    component: 'administration-groups',
  },
  {
    name: 'locations',
    path: '/administration/locations',
    component: 'administration-locations',
  },
  {
    name: 'locationsGroups',
    path: '/administration/locations-groups',
    component: 'administration-locations-groups',
  },
  {
    name: 'issuesTypes',
    path: '/administration/issues-types',
    component: 'administration-issues-types',
  },
  {
    name: 'expectationsTypes',
    path: '/administration/expectations-types',
    component: 'administration-expectations-types',
  },
  {
    name: 'solutionsTypes',
    path: '/administration/solutions-types',
    component: 'administration-solutions-types',
  },
];


const routes = (store: Store<RootState>): RouteRecordRaw[] => {
  const redirectAdministration: RouteRecordRaw = {
    path: '/administration',
    redirect: '/administration/users',
  };
  const administrationRoutesRecords: RouteRecordRaw[] = administrationRoutes.map(
    (route) => vAdministration(store, route.name, route.path, route.component)
  );
  return [
    redirectAdministration,
    vLogin(store),
    cResetPassword(store),
    cSetPassword(store),
    vDashboard(store),
    vCreateIssue(store),
    vIssue(store),
    vController(store),
    vProfile(store),
    ...administrationRoutesRecords,
  ];
};

axios.interceptors.response.use((config) => {
  return config;
}, async (error) => {
  if (error.response.status === 401) {
    Notification.error(
      i18n.global.t(`${GLOBAL}.errors.error`),
      i18n.global.t(`${GLOBAL}.errors.LOGOUT`)
    );
  } else if(error.response.status === 403) {
    Notification.error(
      i18n.global.t(`${GLOBAL}.errors.error`),
      i18n.global.t(`${GLOBAL}.errors.HOTEL_IS_INACTIVE_CONTACT_WITH_ADMIN`)
    );
  }

  return Promise.reject(error);
},
);

export default routes;

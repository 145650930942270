import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_listbox_option = _resolveComponent("listbox-option")

  return (_openBlock(), _createBlock(_component_listbox_option, {
    as: "li",
    class: "group hover:bg-gray-200 p-f8 text-left text-16",
    value: _ctx.item,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitSelect(_ctx.item)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.item), 1)
      ])
    ]),
    _: 3
  }, 8, ["value"]))
}
import { computed, ComputedRef, ref, Ref, watch } from 'vue';
import { getUserUuid, vUsers } from '@/helpers/variables';
import { useGlobals }                             from '@/hooks/use-globals';
import { useGlobalTranslations }                  from '@/hooks/use-translations';
import { Hotel }                                  from '@/store/modules/user/user.store';
import { name as vLogin }                         from '@/views/login/login.route';
import { name as vIssues }                        from '@/views/issues/issues.route';
import { name as vDashboard }                     from '@/views/dashboard/dashboard.route';
import { name as vController }  from '@/views/controller/controller.route';
import { name as vUserProfile } from '@/views/profile/profile.route'
import { iconTypes, MainType }  from '@/types';
import { IssueMainType, UserRole }       from '@/store/store.types';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';

export interface OptionDropdownElement {
    name: string;
    onClick: (event: HTMLElement) => Promise<void> | void;
}

export interface NavigationElement extends OptionDropdownElement {
    icon: string;
}

export interface UseApplicationHeader {
    elementList: ComputedRef<OptionDropdownElement[]>;
    currentUserRole: ComputedRef<string>;
    currentUserFullName:ComputedRef<string>;
    currentUserHotel: ComputedRef<Hotel>;
    currentUserUuid: string;
    activeIcon: Ref<string>;
    navigationElements: NavigationElement[];
    activeNavigationElement: Ref<HTMLElement | null>;
    goToDashboard: () => Promise<void>;
    addRootElement: (root: Ref<HTMLElement | null>) => void;
    setActiveIcon: () => void;
}

const rootElements: Array<Ref<HTMLElement | null>> = [];

export const useApplicationHeader = (): UseApplicationHeader => {
  const { store, router } = useGlobals();
  const currentUserRole: ComputedRef<string> = computed(() => store.getters['user/getUserRole'])
  const currentUserFullName: ComputedRef<string> = computed(() => store.getters['user/fullUserName']);
  const currentUserUuid: string = getUserUuid();

  const { getAllIssues } = useIssues(UseIssuesContext.ISSUES)

  const logoutUser = async (): Promise<void> => {
    await store.dispatch('user/logoutUser');
    await router.push({ name: vLogin });
    const viewModuleKeys: string[] = Object.keys(store.state.views);
    viewModuleKeys.forEach((viewKey: string) => {
      if (store.hasModule(['views', `${viewKey}`]) && viewKey !== vLogin) {
        store.unregisterModule(['views', `${viewKey}`]);
      }
    });

  };

  const defaultHeaderDropdownElements: OptionDropdownElement[] = [

    {
      name: useGlobalTranslations('optionsNavigation.profile'),
      onClick: async () => {
        await router.push({ name: vUserProfile })
      }
    },

    {
      name: useGlobalTranslations('optionsNavigation.dashboard'),
      onClick: async () => {
        await router.push({ name: vDashboard });
      },
    },
    {
      name: useGlobalTranslations('optionsNavigation.logout'),
      onClick: logoutUser,
    }
  ]

  const administrationDropdownElement: OptionDropdownElement = {
    name: useGlobalTranslations('optionsNavigation.administration'),
    onClick: async () => {
      await router.push({ name: vUsers });
    }
  }

  const elementList: ComputedRef<OptionDropdownElement[]> = computed(() => {
    return currentUserRole.value === UserRole.ADMIN
      ? [administrationDropdownElement, ...defaultHeaderDropdownElements]
      : [...defaultHeaderDropdownElements]
  }

  );

  const currentUserHotel: ComputedRef<Hotel> = computed(
    () => store.getters['user/getActiveHotel']
  );
  const activeNavigationElement: Ref<HTMLElement | null> = ref(null);
  const activeIcon = ref(router.currentRoute.value.name === vIssues ? iconTypes.MAINTENANCE : '');
  const navigationElements = [
    {
      name: 'Maintenance',
      icon: iconTypes.MAINTENANCE,
      onClick: async (event: HTMLElement): Promise<void> => {
        activeNavigationElement.value = event;
        activeIcon.value = iconTypes.MAINTENANCE;
        store.commit('setIssueParamsType', IssueMainType.MAINTENANCE);
        store.commit('views/vIssues/closeSidePanel');
        await router.push({ name: vIssues });
        getAllIssues()

      },
    },
    {
      name: 'Care',
      icon: iconTypes.INSURANCE,
      onClick: async (event: HTMLElement): Promise<void> => {
        activeNavigationElement.value = event;
        activeIcon.value = iconTypes.INSURANCE;
        store.commit('setIssueParamsType', IssueMainType.CARE);
        store.commit('views/vIssues/closeSidePanel');
        await router.push({ name: vIssues });
        getAllIssues()
      },
    },
    {
      name: 'Followed',
      icon: iconTypes.VISIBILITY,
      onClick: async (event: HTMLElement): Promise<void> => {
        activeNavigationElement.value = event;
        activeIcon.value = iconTypes.VISIBILITY;
        store.commit('setIssueParamsType', IssueMainType.OBSERVED);
        store.commit('views/vIssues/closeSidePanel');
        await router.push({ name: vIssues });
        getAllIssues()
      },
    },
    {
      name: 'Controller',
      icon: iconTypes.CONTROL,
      onClick: async (event: HTMLElement) => {
        activeNavigationElement.value = event;
        activeIcon.value = iconTypes.CONTROL;
        await router.push({ name: vController })

      }
    }
  ];

  const setActiveIcon = (): void => {
    if (router.currentRoute.value.name === vIssues) {
      switch (store.state.issuesParams.type) {
        case MainType.MAINTENANCE:
          activeIcon.value = iconTypes.MAINTENANCE;
          activeNavigationElement.value = rootElements[0] ? rootElements[0].value : null;
          break;
        case MainType.CARE:
          activeIcon.value = iconTypes.INSURANCE;
          activeNavigationElement.value = rootElements[1] ? rootElements[1].value : null;
          break;
        case MainType.WATCHED:
          activeIcon.value = iconTypes.VISIBILITY;
          activeNavigationElement.value = rootElements[2] ? rootElements[2].value : null;
          break;
        default: {
          break;
        }
      }
    }
    if (router.currentRoute.value.name === vController) {
      activeIcon.value = iconTypes.CONTROL
      activeNavigationElement.value = rootElements[3] ? rootElements[3].value : null;
    }

    if (router.currentRoute.value.name !== vIssues && router.currentRoute.value.name !== vController) {
      activeNavigationElement.value = null;
      activeIcon.value = '';
    }
  }

  const addRootElement = (root: Ref<HTMLElement | null>) => {
    rootElements.push(root);
  }

  watch(router.currentRoute, setActiveIcon);
  watch(() => store.state.issuesParams.type, setActiveIcon)

  const goToDashboard = async (): Promise<void> => {
    await router.push({ name: vDashboard });
  };

  return {
    setActiveIcon,
    goToDashboard,
    elementList,
    currentUserFullName,
    currentUserHotel,
    currentUserUuid,
    activeIcon,
    activeNavigationElement,
    navigationElements,
    addRootElement,
    currentUserRole
  };
};

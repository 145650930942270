export interface State {
  title: string;
}

export type EntityRequest = Record<string, any>; //TODO join all types for administration parts

export type CreateEntityRequest = Record<string, any>; //TODO join all types for administration create, probably use Omit

export enum AdministrationPagination {
  USERS = 'usersTable',
  GROUPS = 'groupsTable',
  LOCATIONS = 'locationsTable',
  LOCATIONS_GROUPS = 'locationsGroupsTable',
  ISSUES_TYPES = 'issuesTypesTable',
  SOLUTIONS_TYPES = 'solutionsTypesTable',
  EXPECTATIONS_TYPES = 'expectationsTypesTable'
}

export enum DeleteEntityResponse {
  DELETE_RESTRICT = 'DELETE_RESTRICT',
  NO_ACTION = 'NO_ACTION'
}

export enum AdministrationEndpoints{
  USERS = 'users',
  LOCATIONS = 'locations',
  GROUPS = 'groups',
  LOCATIONS_GROUPS = 'locations-groups',
  ISSUES_TYPES = 'issues-types',
  SOLUTIONS_TYPES = 'solutions-types',
  EXPECTATIONS_TYPES = 'expectations-types'
}
export enum IssuesEndpoints{
  ISSUES = 'issues',
  FILES='files'
}

export interface QueryParams {
  searchQuery: string;
  filters: any; //TODO Create filters interface
  size: number;
  page: number;
  'sort[0].direction': string;
  'sort[0].field': string;
}

export interface NavigationHighlightStyle {
  height: number;
  width: number;
  positionY: number;
  scaleX: number;
}

export enum FormMode {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

export interface SubmitEvent extends Event {
  submitter: HTMLElement;
}

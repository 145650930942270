import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full flex justify-end" }
const _hoisted_2 = { class: "w-max relative" }
const _hoisted_3 = { class: "text-right text-14 text-bold border-r border-gray-150 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("nav", _hoisted_2, [
      (_ctx.isMounted)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "bg-primary-default z-30 absolute origin-top-right duration-300 transition-transform",
            style: {height: `${_ctx.height}px`,width: `${_ctx.width}px`,transform: `translateY(${_ctx.positionY}px) scaleX(${_ctx.scaleX})`}
          }, null, 4))
        : _createCommentVNode("", true),
      _createVNode("ul", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createBlock("li", {
            key: item,
            ref: (el) => _ctx.getListItemRefs(el, index),
            class: ["px-4 top-0 left-0 mr-1 relative z-50 py-4 cursor-pointer uppercase transition-all duration-300", [_ctx.currentElement === item ? 'text-white' : 'text-gray-850']],
            onClick: ($event: any) => (_ctx.navigateTo($event, item))
          }, _toDisplayString(_ctx.useGlobalTranslations(`routes.${item}`)), 11, ["onClick"]))
        }), 128))
      ])
    ])
  ]))
}
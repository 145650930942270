import { Store }                                                         from 'vuex';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, } from 'vue-router';
import { registerNewModule }                                             from '@/router/router.helpers';
import { getRegisterViewStorePath }                                      from '@/store/store.helpers';
import i18n                                                              from '@/i18n';
import { RootState, }                                                    from '@/store/store.types';

export const name = 'vDashboard';
export const path = '/';

const route = (store: Store<RootState>): RouteRecordRaw => {
  const fetchLocale = async (language?: string): Promise<void> =>
    store.dispatch('i18n/loadTranslations', {
      promise: import(
        /* webpackChunkName: 'views/login/[request]' */ `./locales/${
          language || i18n.global.locale.value
        }.locale.json`
      ),
      language,
      path: name,
    });

  return {
    name: name,
    path: path,
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/dashboard/dashboard.view.vue'),
    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const STORE_PATH: string[] = getRegisterViewStorePath(name);
      const storeExists: boolean = store.hasModule(STORE_PATH);
      const storePromise: () => Promise<void> = async () =>
        import(
          /* webpackChunkName: 'login' */ '@/views/dashboard/store/dashboard.store'
        ).then((data) => {
          registerNewModule(store, STORE_PATH, data.default);
        });
      const promises: Promise<any>[] = [
        ...(!storeExists ? [storePromise()] : []),
        fetchLocale(i18n.global.locale.value.toLowerCase()),
      ];
      await Promise.all(promises);
      next();
    },
    meta: {
      fetchLocale,
    },
  };
};

export default route;

import { IssueType } from '@/views/administration/views/administration-issues-types/administration-issues-types.types';
import { Group } from '@/views/administration/views/administration-groups/administration-groups.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import { Location } from '@/views/administration/views/administration-locations/administration-locations.types';
import { ValidationRuleWithoutParams } from '@vuelidate/core';
import { MainType, RadioGroup, SelectItem } from '@/types';
import { IssueRelatedIssues } from '@/views/issues/issues.types';
import {
  Expectation
} from '@/views/administration/views/administration-expectations-types/administration-expectations-types.types';
import {
  Solution
} from '@/views/administration/views/administration-solutions-types/administration-solutions-types.types';

export interface State {
    v$: any,
    selectedIssueType: MainType,
    maintenance: IssueType[],
    care: IssueType[],
    locations: Location[],
    model: TaskModel,
    taskDto: TaskDto,
    priorities: RadioGroup[],
    groups: Group[],
    users: User[],
    controllers: User[],
    deadlines: Deadlines[],
    selectedTask: any,
    emptyModel: TaskModel,
    tasks: TaskModel[],
    expectationType: Expectation[],
    solutionType: Solution[],
    isLoading: boolean,
}

export interface Priority {
    uuid: string,
    name: Priorities
}

export interface TaskModel {
    name: string,
    priority: string,
    location: string,
    taskType: string,
    tillDate: string,
    deadline: Date | string,
    group: string,
    user: string,
    controller: string,
    description: string,
    guestName: string,
    guestDescription: string,
    expectations: string,
    solution: string,
    expectationType: string,
    solutionType: string,
    issueMainType: MainType,
    relatedIssues: IssueRelatedIssues[];
    files: File[]
}

export interface TaskDto {
    name: string,
    deadline: string | Date,
    controllerUuid: string,
    issueStatus: any,
    mainType: MainType,
    locationUuid: string,
    description: string
    guestName: string,
    guestDescription: string,
    priority: string,
    expectations?: string,
    solution?: string,
    expectationType: string,
    solutionType: string,
    relatedIssues: string[],
    fileUuids: string[],
}

export enum Priorities {
    URGENT = 'URGENT',
    NORMAL = 'NORMAL',
    LOW = 'LOW',
    HIGH = 'HIGH'
}

export enum Deadline {
    TODAY = 'Today',
    WEEK = 'Week',
    TWOWEEKS = '2 weeks',
}

export interface Deadlines {
    name: Deadline;
    uuid: string;
}

export enum IssueStatuses {
    TODO = 'ToDo'
}

export interface IssuesFormRules {
    name: { required: ValidationRuleWithoutParams };
    priority: { required: ValidationRuleWithoutParams };
    location: { required: ValidationRuleWithoutParams };
    taskType: { required: ValidationRuleWithoutParams };
    group: { required: ValidationRuleWithoutParams };
    controller: {required: ValidationRuleWithoutParams}
}

export interface IssueOptions {
    taskTypes: SelectItem[],
    groups: SelectItem[],
    locations: SelectItem[],
    users: SelectItem[],
    expectationType: SelectItem[],
    solutionType: SelectItem[],
    controllers: SelectItem[]
}

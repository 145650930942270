import { AxiosPromise } from 'axios';
import { client } from '@/service/client';
import {
  CreateEntityRequest,
  EntityRequest,
  QueryParams,
} from '@/views/administration/administration.types';

export function getColumnsData(endpoint: string): AxiosPromise {
  return client.get(`/${endpoint}/columns`);
}

export function getTableData(
  endpoint: string,
  params: Partial<QueryParams>
): AxiosPromise {
  return client.get(`/${endpoint}`, {
    params: {
      ...params,
    },
  });
}

export function getFiltersData(endpoint: string): AxiosPromise {
  return client.get(`${endpoint}/filters`);
}

export function getSingleEntity(
  endpoint: string,
  entityUuid: string
): AxiosPromise {
  return client.get(`/${endpoint}/${entityUuid}`);
}

export function deleteSingleEntity(
  endpoint: string,
  entityUuid: string
): AxiosPromise {
  return client.delete(`/${endpoint}/${entityUuid}`);
}

export function updateSingleEntity(
  endpoint: string,
  entityUuid: string,
  payload: EntityRequest
): AxiosPromise {
  return client.put(`/${endpoint}/${entityUuid}`, payload);
}

export function updatePassword(
  endpoint: string,
  entityUuid: string,
  payload: EntityRequest
): AxiosPromise {
  return client.put(`${endpoint}/${entityUuid}/password`, payload);
}

export function updateLanguage(
  endpoint: string,
  entityUuid: string,
  payload: EntityRequest
): AxiosPromise {
  return client.put(`${endpoint}/${entityUuid}/language`, payload);
}

export function createEntity(
  endpoint: string,
  payload: CreateEntityRequest
): AxiosPromise {
  return client.post(`/${endpoint}`, payload);
}

export function getEntitiesToReplace(endpoint: string, entityUuid: string) {
  return client.get(`/${endpoint}/${entityUuid}/$replace-and-delete`);
}

export function replaceEntity(
  endpoint: string,
  entityUuid: string,
  entityToReplaceUuid: string
) {
  return client.put(
    `/${endpoint}/${entityUuid}/$replace-and-delete/${entityToReplaceUuid}`
  );
}

import { AxiosPromise }                  from 'axios';
import { client }                        from '@/service/client';
import { IssueEditPayload, IssueStatus, FilterParams }   from '@/views/issues/issues.types';
import { GetFiltersConfiguration, MainType } from '@/types';
import { IssueMainType, IssuesParams } from '@/store/store.types';
import i18n from '@/i18n';

export function setFiltersConfiguration(payload: any): AxiosPromise {
  return client.put('/issues/filter-configuration', payload);
}

export function getIssues(params: any): AxiosPromise {
  return client.get('/issues', {
    params: {
      field: params.field ? params.field : 'issueNumber',
      direction: params.direction ? params.direction : 'ASC',
      ...params,
    }
  });
}

export function getSingleIssue(issueUuid: string): AxiosPromise {
  return client.get(`/issues/${issueUuid}`);
}

export function setIssue(
  payload: IssueEditPayload,
  issueUuid: string | undefined
): AxiosPromise {
  return client.put(`/issues/${issueUuid}`, {
    ...payload,
  });
}

export function updateIssueStatus(issueUuid: string, issueStatus: IssueStatus): AxiosPromise {
  return client.put(`/issues/${issueUuid}/status`, {
    status: issueStatus
  });
}

export function followIssue(issueUuid: string): AxiosPromise {
  return client.put(`/issues/${issueUuid}/observed`, {
    observe: true
  });
}

export function unFollowIssue(issueUuid: string): AxiosPromise {
  return client.put(`/issues/${issueUuid}/observed`, {
    observe: false
  });
}

export function deleteIssue(
  endpoint: string,
  issueUuid: string
): AxiosPromise {
  return client.delete(`/issues/${issueUuid}`)
}


export function getSingleNote(
  issueUuid: string
): AxiosPromise {
  return client.get(`/issues/${issueUuid}/notes`);
}

export function postNewNote(note: any, issueUuid: string): AxiosPromise {
  return client.post(`/issues/${issueUuid}/notes`, note);
}

export function postNewFile(files: FormData): AxiosPromise {
  return client.post('/files', files)
}

export function deleteFiles(files: File[]): AxiosPromise {
  return client.delete('/files', { data: files })
}

export function getFiltersAndAllIssues(params: FilterParams): AxiosPromise {
  return client.get('/issues', {
    params: {
      ...params
    }
  });
}

export function getPDF(issueUuid: string): AxiosPromise {
  return client.get(`issues/${issueUuid}/pdf/?lang=${i18n.global.locale.value}`, {
    responseType: 'blob'
  })
}
export function getEXCEL(payload: Partial<IssuesParams>): AxiosPromise {
  return client.get('issues/xlsx', {
    params: { ...payload },
    responseType: 'blob'
  })
}

export function getAllFiltersConfigurations(filterType: IssueMainType): AxiosPromise {
  return client.get('/issues/filter-configuration/$get-list', {
    params: {
      filterType
    }
  })
}

export function getFiltersConfiguration( { filterType, configurationUuid }: GetFiltersConfiguration): AxiosPromise {
  return client.get('/issues/filter-configuration/', {
    params: {
      filterType,
      configurationUuid
    }
  });
}

export function createFilterConfiguration({ filterType, name }: {filterType: MainType, name: string} ): AxiosPromise {
  return client.post('issues/filter-configuration', {
    filterType,
    name
  })
}

export function deleteFilterConfiguration(configurationUuid: string): AxiosPromise {
  return client.delete(`issues/filter-configuration/${configurationUuid}`)
}

export function postBaseFilterConfiguration(filterType: MainType): AxiosPromise {
  return client.post('issues/filter-configuration/$create-base-configuration?', {
    filterType
  })
}

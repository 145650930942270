
import { defineComponent }       from 'vue';
import cIconLogout               from '@/components/icons/icon-logout.component.vue';
import { useGlobals }            from '@/hooks/use-globals';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { name as vLogin }        from '@/views/login/login.route';

export default defineComponent({
  name: 'CLogoutButton',
  components: { cIconLogout },
  setup() {
    const { store, router } = useGlobals();

    const logoutUser = async (): Promise<void> => {
      await store.dispatch('user/logoutUser');
      await router.push({ name: vLogin });
    };

    return {
      useGlobalTranslations,
      logoutUser,
    };
  },
});

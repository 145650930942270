import { ApplicationRootState, IssueFilterType, IssueMainType } from './store.types';

export const applicationRootState: ApplicationRootState = {
  pageWidth: window.innerWidth,
  pageHeight: window.innerHeight,
  pagination: {},
  overdueIssue: false,
  issuesParams: {
    type: IssueMainType.MAINTENANCE,
    filter: IssueFilterType.ASSIGNED,
    searchQuery: '',
    time: false,
  },
};

import { postNewFile }   from '@/views/issues/issues.service';
import { AxiosResponse } from 'axios';
import { ColouredPriority, ColouredStatus, Issue, IssueMainType } from '@/views/issues/issues.types';
import i18n from '@/i18n';
import { priorityList, statusList } from '@/views/issues/helpers/variables';
import { defaultCompressionConfig } from '@/helpers/variables';

interface CompressionConfig {
  compressionRatio: number;
  quality: number;
  acceptableSizingThreshold: number;

}

export const downloadPDFFile = (file: Blob, fileName: string): void => {
  const filerUrl = URL.createObjectURL(file);
  const fileLink = document.createElement('a');
  fileLink.href = filerUrl;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
};

export const mappedFilesResource = async (files: File[]): Promise<AxiosResponse> => {
  const formatData = await formatFilesData(files);
  const { data } = await postNewFile(formatData);
  return data;
}


export const formatFilesData = async (files: File[]): Promise<FormData>=> {
  const formData = new FormData()
  const data = await createListCompressedFiles(files)
  appendFilesToFormData(data, formData)
  return formData
};

const createListCompressedFiles = async (files: File[]): Promise<File[]> => {
  const compressedFiles: File[] = [];
  for (const file of files) {
    const compressedFile = await compressImage(file)
    compressedFiles.push(compressedFile)
  }
  return compressedFiles
}

const appendFilesToFormData = (files: File[], formData: FormData) => {
  files.forEach(file => formData.append('files', file, file.name))
}

const compressImage = (file: File, config: CompressionConfig = defaultCompressionConfig): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const img = new Image();

    if(file.size <= config.acceptableSizingThreshold) {
      resolve(file)
    }

    reader.onload = () => {
      img.src = reader.result as string
    }
    img.onload = () => {
      const { width , height } = img
      const canvas = document.createElement('canvas');
      const widthToCompress = config.compressionRatio * width;
      const scaleSize = widthToCompress / width
      canvas.width = widthToCompress;
      canvas.height = scaleSize * height;
      const ctx = canvas.getContext('2d');
      ctx && ctx.drawImage(img, 0, 0);
      ctx && ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        blob
          ? resolve(compareImageSizeAndReturn(file, new File([blob], file.name)))
          : reject('Blob not found')
      },
      file.type,
      config.quality)
    }


    reader.readAsDataURL(file)
  })
}


const compareImageSizeAndReturn = (file: File, secondFile: File): File => {
  return file.size >= secondFile.size
    ? secondFile
    : file
}




export const getIssueDetailsContent = (selectedIssue: Issue) => {
  const status = statusList.find((el: ColouredStatus) => el.name === selectedIssue?.issueStatus);
  return selectedIssue.mainType === IssueMainType.MAINTENANCE
    ? {
      priority: priorityList.find((el: ColouredPriority) => el.name === selectedIssue.priority),
      location: selectedIssue.location.name,
      status: {
        name: i18n.global.t(`vIssues.labels.${status?.name}`),
        color: status?.color
      },
      type: selectedIssue.mainType,
      issueType: selectedIssue.issueType.name,
      created: selectedIssue.creationDate,
      createdBy: selectedIssue.issueCreator.name ? `${selectedIssue.issueCreator.name} ${selectedIssue.issueCreator.lastName}` : '',
      deadline: selectedIssue.deadline || '',
      group: selectedIssue.group.name,
      assigned: selectedIssue.hotelUser.name === null ? '' : `${selectedIssue.hotelUser.name} ${selectedIssue.hotelUser.lastName}`,
      controller: `${selectedIssue.controller.name} ${selectedIssue.controller.lastName}`,
      related: selectedIssue.relatedIssues,
      guest: selectedIssue.guestName,
      guestDescription: selectedIssue.guestDescription,
      endDate: selectedIssue.endDate || '',
      lastUpdateDate: selectedIssue.lastUpdateDate || '',
    }
    : {
      priority: priorityList.find((el: ColouredPriority) => el.name === selectedIssue.priority),
      location: selectedIssue.location.name,
      status: {
        name: i18n.global.t(`vIssues.labels.${status?.name}`),
        color: status?.color
      },
      type: selectedIssue.mainType,
      issueType: selectedIssue.issueType.name,
      created: selectedIssue.creationDate,
      createdBy: selectedIssue.issueCreator.name ? `${selectedIssue.issueCreator.name} ${selectedIssue.issueCreator.lastName}` : '',
      deadline: selectedIssue.deadline || '',
      group: selectedIssue.group.name,
      assigned: selectedIssue.hotelUser.name === null ? '' : `${selectedIssue.hotelUser.name} ${selectedIssue.hotelUser.lastName}`,
      controller: `${selectedIssue.controller.name} ${selectedIssue.controller.lastName}`,
      related: selectedIssue.relatedIssues,
      guest: selectedIssue.guestName,
      guestDescription: selectedIssue.guestDescription,
      endDate: selectedIssue.endDate || '',
      lastUpdateDate: selectedIssue.lastUpdateDate || '',
      expectationType: selectedIssue.expectationType === null ? '' : `${selectedIssue.expectationType.name}`,
      expectation: selectedIssue.expectations === null ? '' : `${selectedIssue.expectations}`,
      solutionType: selectedIssue.solutionType === null ? '' : `${selectedIssue.solutionType.name}`,
      solution: selectedIssue.solution === null ? '' : `${selectedIssue.solution}`
    }
}


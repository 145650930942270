import { Providers }                                               from '@/providers';
import { App, createApp, h }                                       from 'vue';
import { createStore, Store }                                      from 'vuex';
import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import ElementPlus                                                 from 'element-plus';
import routes                                                      from '@/router/router.routes';
import storeConfig                                                 from '@/store/store.config';
import i18n                                                        from '@/i18n';
import MainContent                                                 from '@/components/main-content/main-content.component.vue';
import Root                                                        from '@/root/root.vue';
import { useAxiosInterceptors }                                    from '@/service/service';
import { initiateAxiosInterceptorsInheritance }                    from '@/service/service.helpers';
import { RootState }                                               from '@/store/store.types';
import { useRouterGuards }                                         from '@/router/router.guards';

initiateAxiosInterceptorsInheritance();

const createApplication = (providers: Providers): App => {
  const app = createApp({
    render() {
      return h(Root);
    },
  });

  app.component(<string>MainContent.name, MainContent);

  providers.i18n = i18n;

  providers.store = createStore(storeConfig);

  providers.router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes(providers.store),
  });

  providers.router.afterEach((to: RouteLocationNormalized) => {
    (providers.store as Store<RootState>).dispatch(
      'i18n/changeDocumentTitle',
      to
    );
  });
  useRouterGuards(providers.router, providers.store);
  useAxiosInterceptors();

  return app
    .use(providers.i18n)
    .use(providers.store)
    .use(providers.router)
    .use(ElementPlus);
};

export default createApplication;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-primary-400 ml-2 text-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow_down = _resolveComponent("icon-arrow-down")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.index !== 0)
      ? (_openBlock(), _createBlock(_component_icon_arrow_down, {
          key: 0,
          class: "-rotate-90 transform w-2 h-2 ml-2"
        }))
      : _createCommentVNode("", true),
    (_ctx.index !== _ctx.numberOfElements)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: "ml-2 text-gray-400 cursor-pointer hover:text-primary-400 text-12",
          to: _ctx.navigateTo(_ctx.path)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.path.length ? _ctx.useTranslations(`meta.breadcrumb.${_ctx.path}`) : _ctx.useGlobalTranslations('meta.breadcrumb.dashboard')), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.useTranslations(`meta.breadcrumb.${_ctx.path}`)), 1))
  ], 64))
}
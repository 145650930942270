import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import forEach from 'lodash-es/forEach';

export const getUrl = (path: string): string =>
  `${process.env.VUE_APP_API_PROXY}${path}`;

export const initiateAxiosInterceptorsInheritance = (): void => {
  const orgCreate = axios.create;
  // eslint-disable-next-line func-names
  axios.create = function (config?: AxiosRequestConfig): AxiosInstance {
    // create normal axios instance
    const result = orgCreate.call(this, config as AxiosRequestConfig);

    // add request and response interceptors from original axios to newly created axios instance
    forEach((axios.interceptors.request as any).handlers, (handler) => {
      result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });
    forEach((axios.interceptors.response as any).handlers, (handler) => {
      result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });

    // return enriched axios instance
    return result;
  };
};

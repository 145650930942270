import { MutationTree } from 'vuex';
import { UserDetailsResponse } from '@/store/store.types';
import { generateDefaultHotel } from '@/store/modules/user/user.helpers';
import {
  State,
  UserNotificationsResponse,
} from '@/store/modules/user/user.store';
import { ChangeUserNamePayload } from '@/hooks/use-user';

const mutations: MutationTree<State> = {
  setUserData(state: State, payload: UserDetailsResponse): void {
    state.userFirstName = payload.name;
    state.userLastName = payload.lastName;
    state.role = payload.role;
    state.hotel = payload.hotel;
    state.isController = payload.isController;
    state.uuid = payload.uuid;
    state.language = payload.language;
  },

  setUserNotificationsSettings(state, payload: UserNotificationsResponse) {
    state.userNotificationsSettings.assignedNotification =
      payload.assignedNotification;
    state.userNotificationsSettings.rejectionNotification =
      payload.rejectionNotification;
    state.userNotificationsSettings.smsNotificationsEnabled =
      payload.smsNotificationsEnabled;
    state.userNotificationsSettings.controllerNotification =
      payload.controllerNotification;
    state.userNotificationsSettings.emailIssuesDeadlineEnabled =
      payload.emailIssuesDeadlineEnabled;
    state.userNotificationsSettings.emailIssuesBrieflyToDeadlineEnabled =
      payload.emailIssuesBrieflyToDeadlineEnabled;
    state.userNotificationsSettings.emailIssuesEnabled =
      payload.emailIssuesEnabled;
    state.userNotificationsSettings.phoneNumber = payload.phoneNumber;
  },

  setDefaultStateValue(state: State): void {
    state.userFirstName = '';
    state.isController = false;
    state.userLastName = '';
    state.hotel = generateDefaultHotel();
    state.role = null;
    state.userNotificationsSettings = {
      assignedNotification: false,
      controllerNotification: false,
      rejectionNotification: false,
      smsNotificationsEnabled: false,
      emailIssuesDeadlineEnabled: false,
      emailIssuesBrieflyToDeadlineEnabled: false,
      emailIssuesEnabled: false,
      phoneNumber: '',
    };
  },
  setUserFullName(state, payload: ChangeUserNamePayload) {
    state.userFirstName = payload.name;
    state.userLastName = payload.lastName;
  },
  setUserLanguage(state, payload: string | undefined) {
    if (payload) {
      state.language = payload;
    }
  },
};

export default mutations;

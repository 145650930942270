import { columnTypes } from '@/components/common/table/table.types';
import { State } from '@/store/modules/issues/issues.store';
import { cloneDeep } from 'lodash-es';
import {
  initialFiltersConfiguration,
  priorityList,
  statusList,
  initialFilterState
} from '@/views/issues/helpers/variables';

export const state: State = {
  filtersConfigUuid: '',
  issues: [],
  followedIssues: [],
  selectedIssue: null,
  selectedIssueNotes: [],
  options: {
    issueTypes: [],
    locations: [],
    users: [],
    guests: [],
    groups: [],
    expectationType: [],
    solutionType: []
  },
  selectedIssueFilters: cloneDeep(initialFilterState),
  filtersConfiguration: {
    filters: cloneDeep(initialFiltersConfiguration),
    options: {
      issueTypes: [],
      locations: [],
      users: [],
      guests: [],
      groups: [],
      expectationType: [],
      solutionType: []
    }
  },
  users: [],
  loading: false,
  statusList,
  priorityList,
  tableColumns: [
    { name: 'uuid', type: columnTypes.CHECKBOX },
    { name: 'priority', type: columnTypes.TAGS },
    { name: 'name', type: columnTypes.TEXT },
    { name: 'status', type: columnTypes.TAGS },
    { name: 'created', type: columnTypes.TEXT },
    { name: 'deadline', type: columnTypes.TEXT },
    { name: 'location', type: columnTypes.TEXT },
    { name: 'issueNumber', type: columnTypes.TEXT },
    { name: 'actions', type: columnTypes.ACTIONS },
  ],
  expectationType: [],
  solutionType: [],
  allFiltersConfigurations: []
};
